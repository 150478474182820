import { FETCH_MACHINES } from "../actionType"

const machine = (state = [], action) => {

    switch (action.type) {
        case FETCH_MACHINES:
            return action.payload
        default:
            return state
    }
}

export default machine