import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CurrencyForm from '../../components/CurrencyFrom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Create() {    

    return (
        <Card>
            <Card.Header>
                 <Row>
                    <Col xs={4} md={4}>
                        <Button size="sm" variant="warning" as={Link} to="/currency" title="back">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                    <Col className="align-items-center">
                        Create Currency
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <CurrencyForm update={false} />
            </Card.Body>
        </Card>
    )
}