import React, { Component } from 'react';
import './Viewer.css'

class Viewer extends Component {

    render() {
        return (
            <div className="ck-content" dangerouslySetInnerHTML={{ __html: this.props.htmlContent }}>
                
            </div>
        );
    }
}

export default Viewer;