import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchMachines } from '../actions/machineActions'
import { fetchMetaData } from '../actions/metaDataAction'
import { Card, Row, Col, Form, InputGroup, Button, Alert } from "react-bootstrap"
import { Typeahead } from 'react-bootstrap-typeahead';
import { setCategory, setCountry, setDaysPerYear, setHourPerShift, setMachine, setSGA, setShift, setCurrency, setMargin, setExchangeRate, setDepreciation, setInterest, setMaintenance, setFloorSpace, setInsurance, setUtilities, setHourPerYear, setOperator, setSupport, setCountryExchangeRate } from '../actions/calculationActions'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AuthContext from "../contexts/AuthContext"
import { db } from '../services/firebase'
import {
    toast
} from 'react-toastify'
class Basic extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            category: '',
            country: '',
            currency: '',
            hoursPerYear: 0,
            isGuest: false,
            showLoading: false,
            selectedMachine : []
        }
    }

    componentDidUpdate(prevProps) {
        this.calHoursPerYear(prevProps)
    }

    componentDidMount() {

        const { currentUser } = this.context


        if (currentUser) {
            this.setState({ isGuest: false })
        
        }else {
            this.setState({ isGuest: true })
        }

        this.defaultSetup()

        document.addEventListener("wheel", function (event) {
            if (document.activeElement.type === "number") {
                document.activeElement.blur();
            }
        });
    }

    async defaultSetup() {
        
        let { setShift, setHourPerShift, setCountry, setCurrency, setHourPerYear, setSGA, setMargin } =  this.props
        const { currentUser } = this.context

        const urlSearchParams = new URLSearchParams(window.location.search);

        let machineId = process.env.REACT_APP_DEMO_MACHINE_ID 
        
        if (urlSearchParams.get('machine') !== null ) {
            this.setState({ showLoading : true })
            machineId = urlSearchParams.get('machine')
        }

        let snap = await db.collection('machines').doc(machineId).get();
        let countrySnap = await db.collection('countries').where('name', '==', 'UK').get();
        let currencySnap = await db.collection('currencies').where('code', '==', 'GBP').get();
        let machine = snap.data()

        if (currentUser) {
            this.props.fetchMachines(machine.categoryRef)            
        }
        
        this.props.setMachine(machine)
        this.setState({category : machine.categoryName})
        this.setState({country : 'UK'})
        this.setState({currency : 'GBP'})
        
        
        setShift(2)
        setHourPerShift(7.5)
        setHourPerYear(2,7.5,225)
        this.setState({ hoursPerYear: (2* 7.5* 225)})
        setSGA(12)
        setMargin(5)


        let country = countrySnap.docs[0].data()
        let currency = currencySnap.docs[0].data()


        setCountry(country)
        setCurrency(currency)
        
        await this.props.setExchangeRate(machine.currencyCode, 'GBP')
        await this.props.setCountryExchangeRate(country.currencyCode, 'GBP')

        this.evalCal()

        if (urlSearchParams.get('machine') !== null) {
            this.setState({ showLoading: false })
        }
    }

    async onChangeCountry(e) {
        this.setState({ country: e.target.value })
        let id = e.target[e.target.selectedIndex].getAttribute("data-id")
        let { countries } = this.props.metaData
        let { currency } = this.props;

        let country = countries.filter(item => item.id === id)[0]

        this.props.setCountry(country)

        let code = currency ? currency.code : null
        let countryCurrencyCode = country ? country.currencyCode : null

        await this.props.setCountryExchangeRate(countryCurrencyCode, code)

        this.evalCal()
    }

    async onChangeCurrency(e) {
        this.setState({ currency: e.target.value })

        let id = e.target[e.target.selectedIndex].getAttribute("data-id")
        let { currencies } = this.props.metaData

        let currency = currencies.filter(item => item.id === id)[0]

        this.props.setCurrency(currency)

        let { selectedMachine, country } = this.props

        let machineCurrencyCode = selectedMachine ? selectedMachine.currencyCode : null
        let countryCurrencyCode = country ? country.currencyCode : null

        await this.props.setExchangeRate(machineCurrencyCode, e.target.value)
        await this.props.setCountryExchangeRate(countryCurrencyCode, e.target.value)

        this.evalCal()
    }

    onChangeCategory(e) {
        this.setState({ category: e.target.value })
        let id = e.target[e.target.selectedIndex].getAttribute("data-id");
        let { categories } = this.props.metaData

        let category = categories.filter(item => item.id === id)[0];

        this.props.setCategory(category)

        this.props.fetchMachines(id)

        this.props.setMachine({ name: '' })
    }

    async setSingleSelections(e) {
        let { currency } = this.props;
        let code = currency ? currency.code : null
        let machineCurrencyCode = (e[0]) ? (e[0]).currencyCode : null

        this.props.setMachine(e[0])
        await this.props.setExchangeRate(machineCurrencyCode, code)
        this.evalCal()
    }

    calHoursPerYear(prevProps) {
        let { shift, hoursPerShift, daysPerYear } = this.props

        if (shift != prevProps.shift || hoursPerShift != prevProps.hoursPerShift || daysPerYear != prevProps.daysPerYear) {

            if (isNaN(parseInt(shift)) || isNaN(parseFloat(hoursPerShift)) || isNaN(parseFloat(daysPerYear))) {
                this.setState({ hoursPerYear: 0 })
            } else {
                let hoursPerYear = parseInt(shift) * parseFloat(hoursPerShift) * parseFloat(daysPerYear)
                this.setState({ hoursPerYear })
                this.props.setHourPerYear(shift, hoursPerShift, daysPerYear)

                this.evalCal()
            }
        }


    }

    print() {

        if (!isNaN(this.props.sellingPricePerHour) && this.props.sellingPricePerHour > 0) {
            return <Button title="print" onClick={() => { window.print() }} block>
                <FontAwesomeIcon icon={faPrint} />
            </Button>
        }

    }

    onChangeShift(e) {
        let { hoursPerShift, daysPerYear, country, countryExchangeRate} = this.props
        let value = parseInt(e.target.value)
        //console.log(value *  hoursPerShift* daysPerYear);
        let { setShift, setHourPerYear, setOperator } = this.props
        setShift(value)
        setHourPerYear(value , hoursPerShift , daysPerYear)
        setOperator(country, countryExchangeRate, value)
        //this.evalCal()
    }

    evalCal() {

        let { selectedMachine, country, exchangeRate, hoursPerYear, shift, countryExchangeRate} = this.props


        this.props.setDeprecation(selectedMachine || null, exchangeRate)
        this.props.setInterest(selectedMachine, country, exchangeRate)
        this.props.setMaintenance(selectedMachine, exchangeRate)
        this.props.setFloorSpace(selectedMachine, country, exchangeRate)
        this.props.setInsurance(selectedMachine, country, exchangeRate)
        this.props.setUtilities(selectedMachine, country, hoursPerYear, countryExchangeRate)
        this.props.setOperator(country, countryExchangeRate, shift)

        let { operator } = this.props
        this.props.setSupport(country, operator)
    }

    onDaysPerYearChange(value) {
        let { hoursPerShift, shift } = this.props
        this.props.setDaysPerYear(value)
        this.props.setHourPerYear(shift, hoursPerShift, value)
    }

    onHoursPerShiftChange(value) {
        let { daysPerYear, shift } = this.props
        this.props.setHourPerShift(value)
        this.props.setHourPerYear(shift, value, daysPerYear)
    }

    setSelectedMachine(){

        let { machine, selectedMachine} = this.props

        if (machine.length === 0) {
           return [selectedMachine]
        }

        return machine.filter(item => item.name === selectedMachine.name)
    }

    render() {
        let { categories, countries, currencies } = this.props.metaData
        let { machine, selectedMachine, shift, hoursPerShift, daysPerYear, SGA, margin, sellingPricePerHour } = this.props
        let { setShift, setHourPerShift, setDaysPerYear, setSGA, setMargin } = this.props
        let { category, country, currency, hoursPerYear, isGuest, showLoading } = this.state
        
        // let temp = "Auto saw #2"

        // console.log(temp);
        

        return (
            <Card>
                <Card.Body>
                    {   showLoading ?
                        <Row>
                            <Col>
                                <Alert variant="warning">
                                    Loading...
                                </Alert>
                            </Col>
                        </Row>
                        : null
                    }
                    <Row>
                        <Col sm={6}>
                            <Form.Group controlId="category">
                                <Form.Label>Category</Form.Label>
                                <Form.Control as="select" value={category || ''} onChange={(e) => this.onChangeCategory(e)} disabled={isGuest}>
                                    <option disabled={true} value={""} >--select category--</option>
                                    {
                                        categories.map(category => {
                                            return <option key={category.id} data-id={category.id} value={category.code}>{category.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="category">
                                <Form.Label>Machine</Form.Label>
                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    onChange={(e) => this.setSingleSelections(e)}
                                    options={machine}
                                    disabled={isGuest}
                                    selected={this.setSelectedMachine()}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control value={selectedMachine.description || ''} type="text" disabled/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <Form.Group controlId="shift">
                                <Form.Label>Shift</Form.Label>
                                <Form.Control as="select" value={shift || ''} onChange={(e) => this.onChangeShift(e)}>
                                    <option disabled={true} value={""} >--select shift--</option>
                                    <option value={1} >1</option>
                                    <option value={2} >2</option>
                                    <option value={3} >3</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="hps">
                                <Form.Label>Hours per shift</Form.Label>
                                <Form.Control value={hoursPerShift} type="number" onChange={(e) => this.onHoursPerShiftChange(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="dpy">
                                <Form.Label>Days per year</Form.Label>
                                <Form.Control value={daysPerYear || ''} type="number" onChange={(e) => this.onDaysPerYearChange(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="hpy">
                                <Form.Label>Hours per year</Form.Label>
                                <Form.Control value={hoursPerYear} type="number" disabled={true} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Form.Group controlId="country">
                                <Form.Label>Manufacturing site locn</Form.Label>
                                <Form.Control as="select" value={country || ''} onChange={(e) => this.onChangeCountry(e)}>
                                    <option disabled={true} value={""} >--select manufacturing site locn--</option>
                                    {
                                        countries.map(item => {
                                            return <option key={item.id} data-id={item.id} value={item.name}>{item.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="currency">
                                <Form.Label>Currency</Form.Label>
                                <Form.Control as="select" value={currency || ''} onChange={(e) => this.onChangeCurrency(e)}>
                                    <option disabled={true} value={""} >--select currency--</option>
                                    {
                                        currencies.map(item => {
                                            return <option key={item.id} data-id={item.id} value={item.code}>{item.code}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="SGA">
                                <Form.Label>SGA</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={SGA || ''} name="SGA" onChange={(e) => setSGA(e.target.value)} />

                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="margin">
                                <Form.Label>Margin</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={margin || ''} name="SGA" onChange={(e) => setMargin(e.target.value)} />

                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="margin">
                                <Form.Label>Selling Price Per Hour</Form.Label>
                                <Form.Control type="number" value={isNaN(sellingPricePerHour) ? 0 : sellingPricePerHour.toFixed(2)} disabled />
                            </Form.Group>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
        )
    }
}

const mapStateToProps = state => {

    let { metaData, machine, calculation} = state
    let { shift, SGA, margin, hoursPerShift, daysPerYear, currency, exchangeRate, country, hoursPerYear, operator, sellingPricePerHour, countryExchangeRate } = calculation

    return {
        SGA, margin, hoursPerShift, daysPerYear, exchangeRate, countryExchangeRate, hoursPerYear,
        shift,
        currency,
        machine,
        metaData,
        country,
        operator,
        sellingPricePerHour,
        selectedMachine: calculation.machine,
    }
}

const mapDispatchToProps = dispatch => ({
    fetchMachines: async (categoryId = null) => {
        dispatch(await fetchMachines(categoryId));
    },
    fetchMetaData: async () => {
        dispatch(await fetchMetaData());
    },
    setCategory: (data) => {
        dispatch(setCategory(data));
    },
    setCountry: (data) => {
        dispatch(setCountry(data));
    },
    setHourPerYear: (shift, hoursPerShift, daysPerYear) => {
        dispatch(setHourPerYear(shift, hoursPerShift, daysPerYear));
    },
    setCurrency: (data) => {
        dispatch(setCurrency(data));
    },
    setMachine: (data) => {
        dispatch(setMachine(data));
    },
    setShift: (data) => {
        dispatch(setShift(data));
    },
    setHourPerShift: (data) => {
        dispatch(setHourPerShift(data));
    },
    setDaysPerYear: (data) => {
        dispatch(setDaysPerYear(data));
    },
    setSGA: (data) => {
        dispatch(setSGA(data));
    },
    setExchangeRate: async (machineCurrency, selectedCurrency) => {
        dispatch(await setExchangeRate(machineCurrency, selectedCurrency));
    },
    setMargin: (data) => {
        dispatch(setMargin(data));
    },
    setDeprecation: (machine, exchangeRate) => {
        dispatch(setDepreciation(machine, exchangeRate));
    },
    setInterest: (machine, country, exchangeRate) => {
        dispatch(setInterest(machine, country, exchangeRate));
    },
    setMaintenance: (machine, exchangeRate) => {
        dispatch(setMaintenance(machine, exchangeRate));
    },
    setFloorSpace: (machine, country, exchangeRate) => {
        dispatch(setFloorSpace(machine, country, exchangeRate));
    },
    setInsurance: (machine, country, exchangeRate) => {
        dispatch(setInsurance(machine, country, exchangeRate));
    },
    setUtilities: (machine, country, hoursPerYear, countryExchangeRate) => {
        dispatch(setUtilities(machine, country, hoursPerYear, countryExchangeRate));
    },
    setOperator: (country, countryExchangeRate, shift) => {
        dispatch(setOperator(country, countryExchangeRate, shift));
    },
    setSupport: (country, operator) => {
        dispatch(setSupport(country, operator));
    },
    setCountryExchangeRate: async (country, selectedCurrency) => {
        dispatch(await setCountryExchangeRate(country, selectedCurrency));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Basic);