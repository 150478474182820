const Joi = require('joi')

const createCategory = Joi.object({
    name : Joi.string().trim().required(),
})

const updateCategory = Joi.object({
    name: Joi.string().trim().required(),
})

export {
    createCategory,
    updateCategory
}