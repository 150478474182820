import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import CurrencyForm from '../../components/CurrencyFrom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class Update extends Component {

    state = {
        data :  {
            uid : null
        }
    }

    componentDidMount () {
        let currency = this.props.location.state.currency;

        if (!currency) {

            Swal.fire({
                title: "Fail",
                text: "currency not found",
                type: 'error'
            })

            this.props.history.push('/currency')
        }

        this.setState({
            data: currency
        })
    }

    render() {
        
        return (
            <Card>
                <Card.Header> 
                    <Row>
                        <Col xs={4} md={4}>
                            <Button size="sm" variant="warning" as={Link} to="/currency" title="back">
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                        </Col>
                        <Col className="align-items-center">
                            Update Currency
                        </Col>
                    </Row>
                < /Card.Header>
                <Card.Body>
                    <CurrencyForm updateData={this.state.data} update={true} />
                </Card.Body>
            </Card>
        );
    }
}

export default Update;