import React, { useRef, useState } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { userResetPassword } from '../validation/user'
import { ToastContainer, toast } from 'react-toastify';

export default function ForgotPassword() {

    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const emailRef = useRef()
    const { resetPassword } = useAuth()

    async function onSubmit(e) {

        e.preventDefault()

        setLoading(true)

        try {

            let email = emailRef.current.value

            await userResetPassword.validateAsync({
                email
            }, {
                abortEarly: false
            });

            await resetPassword(emailRef.current.value)

            toast.success("Please check your email");


        } catch (error) {

            setLoading(false)

            let errorMsg = "email not found"

            if (error.details) {
                setErrors(error.details)
                errorMsg = 'Please check you details'
            }


            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,

            });

        }


    }

    const isError = (filed) => {
        for (let error of errors) {
            if (error.context.key === filed) return { status: true, message: error.message }
        }

        return { status: false, message: '' }
    }

    return (
        <Row className="justify-content-center">
            <ToastContainer />
            <Col md={6}>
                <Card>
                    <Card.Body>
                        <h2 className="text-center">Rest Password</h2>

                        <Form onSubmit={onSubmit}>

                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control isInvalid={isError('email').status} type="email" name="email" ref={emailRef} />
                                <Form.Control.Feedback type="invalid">
                                    {isError('email').message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={loading}>
                                Login
                            </Button>
                        </Form>


                    </Card.Body>
                </Card>

                <div className="w-100 text-center mt-2">
                    Have an account? <Link to="/login">Login</Link>
                </div>
            </Col>
        </Row>
    )
}
