import React, { Component } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Bar, Doughnut, Pie, PolarArea } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  options,
  initShowTooltipsAlwaysPlugin,
  barChartOptions,
} from "../helpers/chartJsHelper";

let [
  deprecationColor,
  interestColor,
  maintenanceColor,
  floorSpaceColor,
  utilitiesColor,
  insuranceColor,
  SGAColor,
  marginColor,
  operatorColor,
  supportColor,
] = [
  "#89b6a5",
  "#4c3b4d",
  "#c9eddc",
  "#82968c",
  "#6a706e",
  "#c1666b",
  "#19647e",
  "#ee964b",
  "#f9b9f2",
  "#bca0bc",
];

const chartTypes = [
  { name: "Pie Chart", value: "pie" },
  { name: "Bar Chart", value: "bar" },
];

class Charts extends Component {
  constructor() {
    super();
    this.state = {
      chartType: "pie",
    };
  }

  componentDidMount() {
    initShowTooltipsAlwaysPlugin();
  }

  setChartType(event) {
    this.setState({
      chartType: event.target.value,
    });
  }

  formatChartData(type, data) {
    let types = ["line"];

    let labels = data.map((item) => item.label);

    if (types.includes(type)) {
      return {
        labels,
        datasets: data.map((item) => {
          return {
            label: [item.label],
            data: [item.value],
            backgroundColor: item.color,
            borderColor: item.color,
          };
        }),
      };
    }

    return {
      labels,
      datasets: [
        {
          label: "",
          data: data.map((item) => item.value),
          backgroundColor: data.map((item) => item.color),
        },
      ],
    };
  }

  getChart(type) {
    let data = this.formatChartData(type, this.chartData());

    switch (type) {
      case "pie":
        return <Pie data={data} options={options} />;
      case "polar":
        return <PolarArea data={data} />;
      case "doughnut":
        return <Doughnut data={data} />;

      default:
        return <Bar data={data} options={barChartOptions} />;
    }

    //
  }

  chartData() {
    let {
      deprecation,
      interest,
      maintenance,
      floorSpace,
      utilities,
      operator,
      support,
      insurance,
    } = this.props;

    let SGA = this.calSGAOrMargin("SGA");
    let margin = this.calSGAOrMargin("margin");

    let data = [
      { label: "Deprecation", value: deprecation, color: deprecationColor },
      { label: "Interest", value: interest, color: interestColor },
      { label: "Maintenance", value: maintenance, color: maintenanceColor },
      { label: "Floor Space", value: floorSpace, color: floorSpaceColor },
      { label: "Utilities", value: utilities, color: utilitiesColor },
      { label: "Insurance", value: insurance, color: insuranceColor },
    ];

    let costPerHour = data.map((item) => {
      return {
        label: item.label,
        value: this.calCostPerHour(item.value),
        color: item.color,
      };
    });

    SGA = isNaN(parseFloat(SGA)) ? 0 : parseFloat(SGA).toFixed(2);
    margin = isNaN(parseFloat(margin)) ? 0 : parseFloat(margin).toFixed(2);
    operator = isNaN(parseFloat(operator))
      ? 0
      : parseFloat(operator).toFixed(2);
    support = isNaN(parseFloat(support)) ? 0 : parseFloat(support).toFixed(2);

    costPerHour.push({ label: "SGA", value: SGA, color: SGAColor });
    costPerHour.push({ label: "Margin", value: margin, color: marginColor });
    costPerHour.push({
      label: "Operator",
      value: operator,
      color: operatorColor,
    });
    costPerHour.push({
      label: "Indirect support staff",
      value: support,
      color: supportColor,
    });

    return costPerHour;
  }

  calSGAOrMargin(type) {
    let value = 0;

    let { operator, support, SGA, margin } = this.props;

    let sum = this.sumCostPerHour();

    let placeHolder = type === "SGA" ? SGA : margin;

    value =
      ((placeHolder / 100) * (operator + support + sum)) /
      (1 - SGA / 100 - margin / 100);

    value = isNaN(value) ? 0 : value.toFixed(2);

    return value;
  }

  sumCostPerHour() {
    let value = 0;

    let {
      deprecation,
      interest,
      maintenance,
      floorSpace,
      insurance,
      utilities,
    } = this.props;

    deprecation = this.calCostPerHour(deprecation);
    interest = this.calCostPerHour(interest);
    maintenance = this.calCostPerHour(maintenance);
    floorSpace = this.calCostPerHour(floorSpace);
    insurance = this.calCostPerHour(insurance);
    utilities = this.calCostPerHour(utilities);

    value =
      parseFloat(deprecation) +
      parseFloat(interest) +
      parseFloat(maintenance) +
      parseFloat(floorSpace) +
      parseFloat(insurance) +
      parseFloat(utilities);

    value = isNaN(value) ? 0 : value;

    return value;
  }

  calCostPerHour(value) {
    let { selectedMachine, hoursPerYear } = this.props;

    if (
      isNaN(parseFloat(hoursPerYear)) ||
      isNaN(parseFloat(selectedMachine.oee))
    ) {
      return 0;
    }

    let result = value / ((hoursPerYear * selectedMachine.oee) / 100);

    result = parseFloat(result).toFixed(2);

    result = isNaN(result) || !isFinite(result) ? 0 : result;

    return result;
  }

  render() {
    const { chartType } = this.state;

    const chart = this.getChart(chartType);

    return (
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group controlId="hpy">
                <Form.Label>Chart Type</Form.Label>
                <Form.Control
                  as="select"
                  value={chartType}
                  onChange={(e) => this.setChartType(e)}
                >
                  {chartTypes.map((type) => {
                    return (
                      <option key={type.value} value={type.value}>
                        {type.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="chart-wrapper">{chart}</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  let { calculation } = state;
  let {
    deprecation,
    interest,
    maintenance,
    floorSpace,
    insurance,
    utilities,
    operator,
    support,
    hoursPerYear,
    exchangeRate,
    SGA,
    margin,
  } = calculation;

  return {
    SGA,
    margin,
    exchangeRate,
    deprecation,
    interest,
    maintenance,
    floorSpace,
    insurance,
    utilities,
    hoursPerYear,
    operator,
    support,
    selectedMachine: calculation.machine,
  };
};

export default connect(mapStateToProps)(Charts);
