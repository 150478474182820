import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import CountryForm from '../../components/CountryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Update extends Component {

    state = {
        data :  {
            uid : null
        }
    }

    componentDidMount () {
        let data = this.props.location.state.country;

        if (!data) {

            Swal.fire({
                title: "Fail",
                text: "category not found",
                type: 'error'
            })

            this.props.history.push('/categories')
        }

        this.setState({
            data
        })

    }

    render() {
        
        return (
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={4} md={4}>
                            <Button size="sm" variant="warning" as={Link} to="/country" title="back">
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                        </Col>
                        <Col className="align-items-center">
                            Update country
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <CountryForm updateData={this.state.data} update={true} history={this.props.history} /> 
                </Card.Body>
            </Card>
        );
    }
}

export default Update;