import React, { Component } from 'react';
import { Card,Button, Row,Col } from 'react-bootstrap'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import CategoryForm from '../../components/CategoryForm';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

class Update extends Component {

    state = {
        data :  {
            uid : null
        }
    }

    componentDidMount () {
        let cat = this.props.location.state.cat;

        if (!cat) {

            Swal.fire({
                title: "Fail",
                text: "category not found",
                type: 'error'
            })

            this.props.history.push('/categories')
        }

        this.setState({ data: cat})
    }

    render() {
        
        return (
            <Card>
                <Card.Header><Row>
                    <Col xs={4} md={4}>
                        <Button size="sm" variant="warning" as={Link} to="/categories" title="back">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                    <Col className="align-items-center">
                        Update Category
                    </Col>
                </Row></Card.Header>
                <Card.Body>
                    <CategoryForm updateData={this.state.data} update={true} />
                </Card.Body>
            </Card>
        );
    }
}

export default Update;