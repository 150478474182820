import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMachines } from "../actions/machineActions";
import { ToastContainer } from "react-toastify";
import { fetchMetaData } from "../actions/metaDataAction";
import { Button, ButtonGroup, Alert } from "react-bootstrap";
import Basic from "../components/Basic";
import Cost from "../components/Cost";
import More from "../components/More";
import { resetCalculator } from "../actions/calculationActions";
import Charts from "../components/Charts";
import {
  faBars,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "../contexts/AuthContext";
import { Link } from "react-router-dom";

const navPositionBottom = {
  right: "10px",
  bottom: "32px",
};
const navPositionTop = {
  left: "10px",
  top: "32px",
};
class Calculator extends Component {
  static contextType = AuthContext;

  constructor() {
    super();
    this.state = {
      activeTab: "cal",
      navStatus: false,
      isGuest: false,
      position: "bottom",
    };
  }

  componentDidMount() {
    const { currentUser } = this.context;

    currentUser
      ? this.setState({ isGuest: false })
      : this.setState({ isGuest: true });

    this.props.fetchMetaData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  onChange(activeTab) {
    console.log("onChange", activeTab);

    if (window.innerWidth <= 819) {
      this.setState({ navStatus: false });
    }

    this.setState({ activeTab });

    let baseUri = document.location.href.split("#")[0];

    document.location.href = `${baseUri}#${activeTab}`;
  }

  resize() {
    if (window.innerWidth >= 819) {
      this.setState({ navStatus: true, position: "top" });
      return;
    }

    this.setState({ navStatus: false, position: "bottom" });
  }

  render() {
    let { activeTab, navStatus, isGuest, position } = this.state;
    let calNav;
    let alert;

    if (navStatus) {
      calNav = (
        <ButtonGroup vertical>
          <Button
            variant={activeTab === "cal" ? `secondary` : "outline-secondary"}
            onClick={() => this.onChange(`cal`)}
            aria-controls="cal"
            aria-expanded={activeTab === "cal"}
          >
            Calculation
          </Button>
          <Button
            variant={activeTab === "more" ? `secondary` : "outline-secondary"}
            onClick={() => this.onChange(`more`)}
            aria-controls="more"
            aria-expanded={activeTab === "more"}
          >
            Machine
          </Button>
          <Button
            variant={activeTab === "cost" ? `secondary` : "outline-secondary"}
            onClick={() => this.onChange(`cost`)}
            aria-controls="cost"
            aria-expanded={activeTab === "cost"}
          >
            Cost
          </Button>
          <Button
            variant={activeTab === "chart" ? `secondary` : "outline-secondary"}
            onClick={() => this.onChange(`charts`)}
            aria-controls="chart"
            aria-expanded={activeTab === "chart"}
          >
            Charts
          </Button>

          <Button
            variant="secondary"
            className="mt-4"
            onClick={() => window.print()}
          >
            Print
          </Button>
        </ButtonGroup>
      );
    }

    if (isGuest) {
      alert = (
        <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Please{" "}
          <Link to="/register">register</Link> to access more machines or{" "}
          <Link to="/login">login</Link>
        </Alert>
      );
    }

    return (
      <div>
        <ToastContainer />

        {alert}

        <img src="/img/logo.png" alt="logo" id="printLogo" />

        <div
          className="mt-4"
          style={position === "top" ? navPositionTop : navPositionBottom}
          id="calNav"
        >
          <Button
            variant="secondary"
            className="mt-4 mb-4"
            size="lg"
            onClick={() => this.setState({ navStatus: !navStatus })}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
          {calNav}
        </div>
        <div id="cal" className="mt-5">
          <Basic />
        </div>
        <div id="more" className="mt-5">
          <More />
        </div>
        <span className="page-break" style={{ pageBreakAfter: "always" }}>
          &nbsp;
        </span>
        <span className="page-break" style={{ pageBreakBefore: "always" }}>
          &nbsp;
        </span>
        <div id="cost" className="mt-1">
          <Cost />
        </div>
        <div id="charts" className="mt-5 mb-5">
          <Charts />
        </div>
        <p id="footer" className="text-center">
          <b>
            {window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname}
          </b>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    machines: state.machine,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMachines: async (categoryId = null) => {
    dispatch(await fetchMachines(categoryId));
  },
  fetchMetaData: async () => {
    dispatch(await fetchMetaData());
  },
  resetCalculator: () => {
    dispatch(resetCalculator());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
