import { toast } from "react-toastify";
import { FETCH_META_DATA } from "../actionType";
import { fetchStructuredActiveDocsFromCollections } from "./helpers";

export async function fetchMetaData() {

    let wait = toast.warning('Please wait!')
    
    let categories = await fetchStructuredActiveDocsFromCollections('categories')
    let currencies = await fetchStructuredActiveDocsFromCollections('currencies')
    let countries= await fetchStructuredActiveDocsFromCollections('countries')

    toast.update(wait, {
        autoClose: 1
    })


    return {
        type: FETCH_META_DATA,
        payload: {
            currencies,
            categories,
            countries
        }
    }

}