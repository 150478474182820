import React from 'react'
import RegisterForm from '../../components/RegisterForm'
import { Card } from 'react-bootstrap'

export default function Create() {
    return (
        <div>
            <Card>
                <Card.Header>Create User</Card.Header>
                <Card.Body>
                    <RegisterForm redirect="/users" />
                </Card.Body>
            </Card>
        </div>
    )
}
