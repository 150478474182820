import React, { Component } from 'react'
import { Card, Row, Col, InputGroup, FormControl, Button, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faToggleOn, faToggleOff, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../services/firebase"
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Link } from 'react-router-dom';

class List extends Component {

    state = {
        currencyList: [],
        searchList: [],
        searchKey: "",
        loadingStatueActive: false,
        loading: true
    }

    getCurrencyList = async () => {

        this.setState({ loading: true })

        try {
            let snap = await db.collection('currencies').orderBy('createAt', "desc").get()
            this.setState({ currencyList: snap.docs })
            let searchable = snap.docs.map(el => {

                console.log();
                return {
                    uid: el.id,
                    ...el.data()
                }

            })
            this.setState({ searchList: searchable })
        } catch (error) {
            console.log(error);
        }

        this.setState({ loading: false })
    }

    componentDidMount() {
        this.getCurrencyList()
    }

    searchCurrency = (e) => {


        let { searchKey, currencyList } = this.state

        let results = currencyList.filter(currency => {
            let { name } = currency.data()
            return name.toLowerCase().includes(searchKey)
        })

        let searchable = results.map(el => {
            return {
                uid: el.id,
                ...el.data()
            }

        })

        console.log(searchable);

        this.setState({ searchList: searchable })
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) this.searchCurrency()

        if (e.keyCode === 27) {
            this.setState({ searchKey: '' })

            let searchable = this.state.currencyList.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })

            this.setState({ searchList: searchable })
        }
    }

    deleteCurrency = async (row) => {

        let result = await Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: "This will delete all the exchange rates under this currency",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Delete`,
        })

        if (result.value === true) {

            let wait = toast.warning("please wait");

            try {

                if (await this.checkDependency(row.uid, 'machines')) {
                    toast.update(wait, {
                        autoClose: 1
                    })
                    toast.error("can not delete this currency, has active machines under this currency");
                    return
                }
                
                if (await this.checkDependency(row.uid, 'countries')) {
                    toast.update(wait, {
                        autoClose: 1
                    })
                    toast.error("can not delete this currency, has active countries under this currency");
                    return
                }

                await db.collection("currencies").doc(row.uid).delete()

                let batch = db.batch()

                let rates = await db.collection('rates').get()

                rates.docs.forEach(doc => {

                    if (doc.id.includes(row.code)) {
                        batch.delete(doc.ref)
                    }

                })

                await batch.commit()

                await this.getCurrencyList()

                toast.update(wait, {
                    autoClose: 1
                })

                toast.success("success");

            } catch (error) {

                console.log(error);

                toast.error("you don't have enough permission");

            }

            toast.update(wait, {
                autoClose: 1
            })
        }

    }

    columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Code',
            selector: 'code',
            sortable: true,
        },
        {
            name: 'Active',
            cell: row => {

                return row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />

            },
        },
        {
            name: "Action",
            cell: row => {

                return <ButtonGroup>
                    <Button size="sm" title="change status" onClick={() => { this.changeStatus(row) }} >
                        {row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}
                    </Button>
                    <Button size="sm" variant="success" title="edit currency" onClick={() => { this.updateCurrency(row) }} >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button size="sm" variant="danger" title="delete category" onClick={() => { this.deleteCurrency(row) }} >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </ButtonGroup>

            },
            ignoreRowClick: true,
            allowOverflow: true,
        }
    ]

    checkDependency = async (id, collection) => {

        let dependency = await db.collection(collection).where('currencyRef', '==', id).where('isActive', '==', true).get()

        return dependency.docs.length > 0

    }


    updateCurrency = async (row) => {

        let result = await Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: "updating currency code will delete the exchange rate",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Continue`,
        })

        if (result.value === true) {
            this.props.history.push('/currency-update', { currency: row })
        }


    }

    changeStatus = async (row) => {

        try {

            await db.collection("currencies").doc(row.uid).update({
                isActive: !row.isActive
            })

            await this.getCurrencyList()

            toast.success("success", {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,

            });

        } catch (error) {


            toast.error("you don't have enough permission", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,

            });

        }

        this.setState({ loadingStatueActive: false })

    }

    onChange = (e) => {
        this.setState({ searchKey: e.target.value })
    }

    render() {
        return (
            <>
                <ToastContainer />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                Currencies
                            </Col>
                            <Col className="text-right">
                                <Button size="sm" as={Link} to="/currency-create" title="create currency">
                                    < FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </Col>
                        </Row>

                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        onChange={this.onChange}
                                        onKeyDown={this.onKeyDown}
                                        placeholder="search currencies"
                                        aria-label="search currencies"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Append>
                                        <Button variant="success" size="x" onClick={() => this.searchCurrency()}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <DataTable
                                    pagination={true}
                                    columns={this.columns}
                                    data={this.state.searchList}
                                    progressPending={this.state.loading}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>

        );
    }
}

export default List;