import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import AuthContext from "../contexts/AuthContext"
import { toast, ToastContainer } from 'react-toastify'
import { db, storage } from '../services/firebase';
import Editor from '../components/Editor';
import Viewer from '../components/Viewer';

class HowTo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pdf: null,
            content: "loading",
            unableEditor: false
        }
    }

    static contextType = AuthContext


    
    async setContent() {

        let wait = toast.warning('Please wait', { autoClose: false })

        try {


            let settingCollection = db.collection('settings')
            let doc = await settingCollection.doc("howTo").get()
            let content = doc.data().content
            console.log(content);
            this.setState({ content })


        }
        catch (e) {
            toast.error("document not set")

        }

        toast.update(wait, { autoClose: 1 })

    }

    componentDidMount() {
        this.setContent()
    }


    updateContent(content) {
        this.setState({ content })
    }

    render() {

        let { content, unableEditor } = this.state
        const { currentUser } = this.context
        const isAdmin = currentUser && currentUser.uid === process.env.REACT_APP_ADMIN_UID

        return (
            <div>
                <ToastContainer />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={8} xs={8}>
                                Start here
                            </Col>
                            <Col xs={4} md={4} className="text-right">
                                {
                                    isAdmin ?
                                        <Button size="sm" variant="warning" title="open editor" onClick={() => this.setState({ unableEditor: !unableEditor })}>
                                            <FontAwesomeIcon icon={faPen} />
                                        </Button> : null
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {   
                            unableEditor ?
                                <Editor content={content} onContentChange={e => this.updateContent(e)} /> :
                                <Viewer htmlContent={content} />
                        }
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default HowTo;