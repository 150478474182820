import React, { Component } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import MachineForm from '../../components/MachineForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
export default class Create extends Component {
    render() {
        return (
            <Card>
                <Card.Header>
                    <Row>
                    <Col xs={4} md={4}>
                        <Button size="sm" variant="warning" as={Link} to="/machine" title="back">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                    <Col className="align-items-center">
                        Create Machine
                    </Col>
                </Row>
                </Card.Header>
                <Card.Body>
                    <MachineForm update={false} />
                </Card.Body>
            </Card>
        )
    }
}
