import React, { Component } from 'react'
import { Card, Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../services/firebase"
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

class List extends Component {

    state = {
        usersList: [],
        searchList: [],
        searchKey: "",
        loadingStatueActive: false,
        loading : true
    }

    

    getUserList = async () => {

        this.setState({ loading : true})

        let snap = await db.collection('users').orderBy('createAt', "desc").get()
        this.setState({ usersList: snap.docs })
        let searchable = snap.docs.map(el => {
            return {
                uid: el.id, 
                ...el.data()
            }

        })
        this.setState({ searchList: searchable })

        this.setState({ loading : false})
    }

    componentDidMount() {
        this.getUserList()
    }

    changeStatus = async (row) => {

           try {

               await db.collection("users").doc(row.uid).update({
                   isActive: !row.isActive
               })

               toast.success("success", {
                   position: "top-right",
                   closeOnClick: true,
                   pauseOnHover: false,
                   draggable: true,
                   progress: 0,

               });

               await this.getUserList()

               

           } catch (error) {


               toast.error("you don't have enough permission", {
                   position: "top-right",
                   autoClose: 5000,
                   closeOnClick: true,
                   pauseOnHover: false,
                   draggable: true,
                   progress: 0,

               });

           }

        this.setState({ loadingStatueActive: false })

    }


    columns = [
        {
            name: 'First Name',
            selector: 'firstName',
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: 'lastName',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
        },
        {
            name: 'Active',
            cell: row => {

                return row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />

            },
        },
        {
            name: "Action",
            cell: row => {

                return <Button size="sm" onClick={() => { this.changeStatus(row) }} >change status</Button>

            },
            ignoreRowClick: true,
            allowOverflow: true,
        }
    ]

    searchUser = (e)  => {
        
        let { searchKey, usersList } = this.state

        let results = usersList.filter(user => {
            let { firstName ,  lastName, email } = user.data()

            return firstName.toLowerCase().includes(searchKey) || lastName.toLowerCase().includes(searchKey) || email.toLowerCase().includes(searchKey)

        })

        let searchable = results.map(el => {
            return {
                uid: el.id,
                ...el.data()
            }

        })

        this.setState({ searchList : searchable})
    }

    onChange =  (e) => {
        this.setState({ searchKey : e.target.value})
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) this.searchUser()

        if(e.keyCode === 27) {
            this.setState({searchKey : ''})

            let searchable = this.state.usersList.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })

            this.setState({ searchList: searchable })
        }
    }

    render() {

        return (
            <div>
                <ToastContainer />
                <Card>
                    <Card.Header>Users</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        onChange={this.onChange}
                                        onKeyDown={this.onKeyDown}
                                        value={this.state.searchKey}
                                        placeholder="search users"
                                        aria-label="search users"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Append>
                                        <Button variant="success" onClick={() => this.searchUser()}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <DataTable
                                    pagination={true}
                                    columns={this.columns}
                                    progressPending={this.state.loading}
                                    data={this.state.searchList}
                                />
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default List;