import React, { useEffect, useState } from 'react'
import {
    Form,
    Button
} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import {  useHistory } from 'react-router-dom';
import { db } from '../services/firebase';
import { createCategory, updateCategory } from '../validation/category';
import Swal from 'sweetalert2/dist/sweetalert2.js'


export default function CategoryForm(props) {
    
    const [id, setId] = useState(null)
    const [name, setName] = useState(props.update && props.updateData.name)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const history = useHistory()

    useEffect(() => {
        
        if (props.update) {
            setName(props.updateData.name)
            setId(props.updateData.uid)
        }

    }, [props])

     async function onSubmit(e) {

         e.preventDefault()

         setLoading(true)

         try {

             if(!props.update) {
                 await createNewCategory()
             }

             if (props.update) {
                 await updateExistingCategory()
             }


         } catch (error) {

            console.log(error);
            

             let errorMsg = "you don't have enough permission"

             if (error.details) {
                 setErrors(error.details)
                 errorMsg = 'Please check you details'
             }


             toast.error(errorMsg);

         }

         setLoading(false)

     }


     const isError = (filed) => {
         for (let error of errors) {
             if (error.context.key === filed) return {
                 status: true,
                 message: error.message
             }
         }

         return {
             status: false,
             message: ''
         }
     }

    function onChange (e) {
        setName(e.target.value)
    }

    async function createNewCategory() {

        await createCategory.validateAsync({
            name
        }, {
            abortEarly: false
        });

        await db.collection('categories').add({ name, isActive : true, createAt: new Date().toISOString() })

        redirect()
    }

    async function updateExistingCategory() {

        await updateCategory.validateAsync({
            name
        }, {
            abortEarly: false
        });

        await db.collection('categories').doc(id).update({
            name,
            updateAt: new Date().toISOString()
        })

        await updateMachineCatNames()

        redirect()
    }

    async function updateMachineCatNames() {

       let batch = db.batch();

       let machines = await db.collection('machines').where('categoryRef', '==', id).get()

        machines.docs.forEach(ref => {
            batch.update(ref.ref, {categoryName : name})
        })

        await batch.commit()
        
    }

    function redirect () {

        let msg = `category ${props.update ? 'updated' : 'added'} successfully`

        Swal.fire({
            title : "Success",
            text: msg,
            type : 'success'
        }).then(result => {

            if(result.value) history.push('/categories')

        })
    }

    return (
        <div>
            <ToastContainer />
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control isInvalid={isError('name').status} type="text" value={name || ''} name="name" onChange={onChange}/>
                        <Form.Control.Feedback type="invalid">
                            {isError('name').message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Button variant="primary" type="submit" disabled={loading}>
                        Submit
                    </Button>
                </Form>
        </div>
    )
}

