import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import {Card, Button, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MyDropzone from '../../components/MyDropzone';

class Images extends Component {
    render() {
        return (
            <div>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={4} md={4}>
                                <Button size="sm" variant="warning" as={Link} to="/machine" title="back">
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                            </Col>
                            <Col className="align-items-center">
                                Machine Images
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <MyDropzone/>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default Images;