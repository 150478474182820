import React, { Component } from 'react'
import LoginForm from '../components/LoginForm'
import { Card, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default class Login extends Component {
    render() {
        return (
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center">Login</h2>
                            <LoginForm />

                            <p className="mt-3">
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </p>
                        </Card.Body>
                    </Card>

                    <div className="w-100 text-center mt-2">
                        Need an account? <Link to="/register">Sign Up</Link>
                    </div>
                </Col>
            </Row>
        )
    }
}
