import { storage as fbStorage } from "../services/firebase";
import firebase from "firebase/app";

export class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }
    // Starts the upload process.
    upload() {
        return this.loader.file.then(
            file =>
            new Promise((resolve, reject) => {
                let storage = fbStorage.ref();
                let uploadTask = storage
                    .child(file.name)
                    .put(file);
                uploadTask.on(
                    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    function (snapshot) {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        
                        
                    },
                    function (error) {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        // eslint-disable-next-line default-case
                        switch (error.code) {
                            case "storage/unauthorized":
                                reject(" User doesn't have permission to access the object");
                                break;

                            case "storage/canceled":
                                reject("User canceled the upload");
                                break;

                            case "storage/unknown":
                                reject(
                                    "Unknown error occurred, inspect error.serverResponse"
                                );
                                break;
                        }
                    },
                    function () {
                        // Upload completed successfully, now we can get the download URL
                        uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(function (downloadURL) {
                                // console.log("File available at", downloadURL);
                                resolve({
                                    default: downloadURL
                                });
                            });
                    }
                );
            })
        );
    }
}