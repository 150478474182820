import { FETCH_META_DATA } from "../actionType"

const INITIAL_STATE = {
    currencies: [],
    categories: [],
    countries: []
}

const metaData = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case FETCH_META_DATA:
            return action.payload
        default:
            return state
    }
}

export default metaData