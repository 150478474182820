import {
    SET_MACHINE,
    SET_CATEGORY,
    SET_EXCHANGE,
    SET_COUNTRY,
    SET_SHIFT,
    SET_HOURS_PER_SHIFT,
    SET_DAYS_PER_YEAR,
    SET_MARGIN,
    SET_SGA,
    SET_CURRENCY,
    SET_DEPRECATION,
    SET_INTEREST,
    SET_MAINTENANCE,
    SET_INSURANCE,
    SET_FLOOR_SPACE,
    SET_UTILITIES,
    SET_HOURS_PER_YEAR,
    SET_OPERATOR,
    SET_SUPPORT,
    SET_SELLING_PRICE_PER_HOUR,
    REST_CALCULATOR,
    SET_COUNTRY_EXCHANGE_RATE
} from '../actionType'


const INITIAL_STATE = {
    hoursPerYear: 0,
    machine : {name : ''},
    category : {},
    exchangeRate : 0,
    countryExchangeRate : 0,
    country : {},
    currency : {},
    shift : "",
    hoursPerShift : 0,
    daysPerYear: 225,
    SGA : 0,
    margin : 0,
    deprecation : 0,
    interest : 0,
    maintenance : 0,
    floorSpace : 0,
    insurance : 0,
    utilities : 0,
    operator : 0,
    support : 0,
    sellingPricePerHour : 0
}


const calculator = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case SET_MACHINE:
            return {
                ...state,
                machine: action.payload
            }
        case SET_CATEGORY:
            return {
                ...state,
                category: action.payload
            }
        case SET_EXCHANGE:
            return {
                ...state,
                exchangeRate: action.payload
            }
        case SET_COUNTRY:
            return {
                ...state,
                country: action.payload
            }
        case SET_SHIFT:
            return {
                ...state,
                shift: action.payload
            }
        case SET_HOURS_PER_SHIFT:
            return {
                ...state,
                hoursPerShift: action.payload
            }
        case SET_DAYS_PER_YEAR:
            return {
                ...state,
                daysPerYear: action.payload
            }
        case SET_SGA:
            return {
                ...state,
                SGA: action.payload
            }
        case SET_MARGIN:
            return {
                ...state,
                margin: action.payload
            }
        case SET_CURRENCY:
            return {
                ...state,
                currency: action.payload
            }
        case SET_DEPRECATION:
            return {
                ...state,
                deprecation: action.payload
            }
        case SET_INTEREST:
            return {
                ...state,
                interest: action.payload
            }
        case SET_MAINTENANCE:
            return {
                ...state,
                maintenance: action.payload
            }
        case SET_FLOOR_SPACE:
            return {
                ...state,
                floorSpace: action.payload
            }
        case SET_INSURANCE:
            return {
                ...state,
                insurance: action.payload
            }
        case SET_UTILITIES:
            return {
                ...state,
                utilities: action.payload
            }
        case SET_HOURS_PER_YEAR:
            return {
                ...state,
                hoursPerYear: action.payload
            }
        case SET_OPERATOR:
            return {
                ...state,
                operator: action.payload
            }
        case SET_SUPPORT:
            return {
                ...state,
                support: action.payload
            }
        case SET_SELLING_PRICE_PER_HOUR:
            return {
                ...state,
                sellingPricePerHour: action.payload
            }
        case REST_CALCULATOR:
            return {
                ...state,
            }
        case SET_COUNTRY_EXCHANGE_RATE:
            return {
                ...state,
                countryExchangeRate: action.payload
            }
        default:
            return state
    }
}

export default calculator