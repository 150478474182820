import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Button } from "react-bootstrap"
import { Pie, Chart } from 'react-chartjs-2';
import { setSellingPricePerHour } from '../actions/calculationActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { options, initShowTooltipsAlwaysPlugin } from '../helpers/chartJsHelper'


class Cost extends Component {

    constructor() {
        super()
        this.state = {
            chart: null
        }
    }

    // componentDidMount(){
    //     initShowTooltipsAlwaysPlugin()
    // }

    calCostPerHour(value) {

        let { selectedMachine, hoursPerYear } = this.props

        if (isNaN(parseFloat(hoursPerYear)) || isNaN(parseFloat(selectedMachine.oee))) {
            return 0
        }

        let result = value / (hoursPerYear * selectedMachine.oee / 100)

        result = parseFloat(result).toFixed(2)

        result = isNaN(result) || !isFinite(result) ? 0 : result

        return result
    }

    calTotalCostPerYear() {

        let total = 0

        let { deprecation, interest, maintenance, floorSpace, insurance, utilities } = this.props

        total = deprecation + interest + maintenance + floorSpace + insurance + utilities

        if (isNaN(total)) return 0

        total = Math.round(total)

        return total.toLocaleString()
    }

    calSGAOrMargin(type) {
        let value = 0

        let { operator,
            support, SGA, margin } = this.props


        let sum = this.sumCostPerHour()

        let placeHolder = type === 'SGA' ? SGA : margin

        value = ((placeHolder / 100) * (operator + support + sum)) / (1 - (SGA / 100) - (margin / 100))

        value = isNaN(value) ? 0 : value.toFixed(2)

        return value
    }

    sumCostPerHour() {

        let value = 0

        let { deprecation,
            interest,
            maintenance,
            floorSpace,
            insurance,
            utilities } = this.props

        deprecation = this.calCostPerHour(deprecation)
        interest = this.calCostPerHour(interest)
        maintenance = this.calCostPerHour(maintenance)
        floorSpace = this.calCostPerHour(floorSpace)
        insurance = this.calCostPerHour(insurance)
        utilities = this.calCostPerHour(utilities)

        value = parseFloat(deprecation) + parseFloat(interest) + parseFloat(maintenance) + parseFloat(floorSpace) + parseFloat(insurance) + parseFloat(utilities)

        value = isNaN(value) ? 0 : value

        return value
    }

    calSellingPricePerHour() {

        let value = 0
        let {operator, support} = this.props

        let sum = this.sumCostPerHour()
        let SGA = this.calSGAOrMargin('SGA')
        let margin = this.calSGAOrMargin('margin')

        value = (sum + operator + support) + parseFloat(SGA) + parseFloat(margin)

        // value = isNaN(value) ? 0 : value.toFixed(1)

        if(isNaN(value)) value = 0

        this.props.setSellingPricePerHour(value)
        

        return value.toFixed(2)

    }

    chartData() {

        let { deprecation, interest,
            maintenance,
            floorSpace,
            utilities,
            operator,
            support,
            insurance } = this.props

        let SGA = this.calSGAOrMargin('SGA')
        let margin = this.calSGAOrMargin('margin')

        let data = [deprecation, interest,
            maintenance,
            floorSpace,
            utilities,
            insurance];


        let costPerHour = data.map(item => this.calCostPerHour(item))


        costPerHour.push(SGA)
        costPerHour.push(margin)
        costPerHour.push(operator)
        costPerHour.push(support)

        return costPerHour
    }

    print() {

        if (!isNaN(this.props.sellingPricePerHour) && this.props.sellingPricePerHour > 0) {
            return <Button title="print" onClick={() => { window.print() }} block>
                <FontAwesomeIcon icon={faPrint} />
            </Button>
        }

    }

    render() {

        let { exchangeRate, countryExchangeRate, selectedMachine, deprecation, interest,
            maintenance,
            floorSpace,
            operator,
            support,
            insurance,
            utilities, currency} = this.props
        let { oee } = selectedMachine


        const temp = (canvas) => {

            return {
                labels: ['Depreciation', 'Interest', 'Maintenance', 'Floor space', 'Utilities', 'Insurance', 'SGA', 'Margin', 'Operator', 'Indirect support staff'],
                datasets: [
                    {
                        data: this.chartData(),
                        backgroundColor: [
                            '#89b6a5',
                            '#4c3b4d',
                            '#c9eddc',
                            '#82968c',
                            '#6a706e',
                            '#c1666b',
                            '#19647e',
                            '#ee964b',
                            '#f9b9f2',
                            '#bca0bc',
                        ]
                    },
                ],
                options
            }
        }

        return (
            <Card>
                <Card.Body>
                    {/* <Row>
                        <Col>
                            <Form.Group controlId="hpy">
                                <Form.Label>Machine exchange Rate</Form.Label>
                                <Form.Control value={exchangeRate} type="number" disabled={true} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="hpy">
                                <Form.Label>Country exchange Rate</Form.Label>
                                <Form.Control value={countryExchangeRate} type="number" disabled={true} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="hpy">
                                <Form.Label>OEE</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={oee || ''} disabled={true} />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row> */}
                    {/* <Row className="justify-content-center">
                        <Col>
                            <Pie data={temp} options={options}/>
                            <div id='chart'>

                            </div>
                        </Col>
                    </Row> */}
                    <Row className="mt-4 justify-content-center">
                        <Col>
                            <table id="costTable" align="center">
                                <thead>
                                    <tr>
                                        <th width="200px">

                                        </th>
                                        <th width="200px" className="text-center pr-3" >
                                            <p>Cost per year </p>
                                            <p>({currency.code})</p>
                                        </th>
                                        <th width="200px" className="text-center">
                                            <p>Cost per hour</p>
                                            <p>({currency.code})</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr id="deprecationRow">
                                        <td>
                                            <p>
                                                <span></span>Depreciation
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {deprecation.toLocaleString()}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {this.calCostPerHour(deprecation)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="interestRow">
                                        <td>
                                            <p>
                                                <span></span>Interest
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {interest.toLocaleString()}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {this.calCostPerHour(interest)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="maintenanceRow">
                                        <td>
                                            <p>
                                                Maintenance
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {maintenance.toLocaleString()}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {this.calCostPerHour(maintenance)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="floorSpaceRow">
                                        <td>
                                            <p>
                                                Floor space
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {floorSpace.toLocaleString()}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {this.calCostPerHour(floorSpace)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="insurance">
                                        <td>
                                            <p>
                                                Insurance
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {insurance.toLocaleString()}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {this.calCostPerHour(insurance)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="utilities">
                                        <td>
                                            <p>
                                                Utilities
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {utilities.toLocaleString()}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {this.calCostPerHour(utilities)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Total machine cost / year</p>
                                        </td>
                                        <td>
                                            <p>
                                                <b>{this.calTotalCostPerYear()}</b>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="operatorRow">
                                        <td>
                                            <p>
                                                Operator
                                            </p>
                                        </td>
                                        <td></td>
                                        <td>
                                            <p>
                                                {operator.toFixed(2)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="supportRow">
                                        <td>
                                            <p>
                                                Indirect support staff
                                            </p>
                                        </td>
                                        <td></td>
                                        <td>
                                            <p>
                                                {support.toFixed(2)}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="SGARow">
                                        <td>
                                            <p>
                                                SGA
                                            </p>
                                        </td>
                                        <td></td>
                                        <td>
                                            <p>
                                                {this.calSGAOrMargin('SGA')}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="marginRow">
                                        <td>
                                            <p>
                                                Margin
                                            </p>
                                        </td>
                                        <td></td>
                                        <td>
                                            <p>
                                                {this.calSGAOrMargin('margin')}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr id="totalRow">
                                        <td>
                                            <p>
                                                <b>Selling price per hour</b>
                                            </p>
                                        </td>
                                        <td></td>
                                        <td>
                                            <p>
                                                <b>{this.calSellingPricePerHour()}</b>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

const mapStateToProps = state => {

    let { metaData, machine, calculation } = state
    let { deprecation,
        interest,
        maintenance,
        floorSpace,
        insurance,
        utilities,
        operator,
        support,
        hoursPerYear, exchangeRate, SGA, margin, sellingPricePerHour, countryExchangeRate, currency } = calculation

    return {
        SGA,
        margin,
        exchangeRate,
        deprecation,
        interest,
        maintenance,
        floorSpace,
        insurance,
        utilities,
        hoursPerYear,
        machine,
        metaData,
        operator,
        support,
        sellingPricePerHour,
        countryExchangeRate,
        currency,
        selectedMachine: calculation.machine,
    }
}

const mapDispatchToProps = dispatch => ({
    setSellingPricePerHour:(price) => {
        dispatch(setSellingPricePerHour(price));
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(Cost);