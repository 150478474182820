import React, { Component } from 'react';
import {
    Form,
    Button,
    InputGroup
} from 'react-bootstrap'
import { authSecondary, db } from "../services/firebase"
import { ToastContainer, toast } from 'react-toastify';

import { userRegisterRule } from "../validation/user"
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'


class RegisterForm extends Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        errors: [],
        loading: false,
        redirect: null
    }

    onChange = (e) => {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }

    onSubmit = async (e) => {

        e.preventDefault()

        this.setState({ loading: true })
        this.setState({ errors: [] })

        try {

            let { firstName, lastName, email, password, passwordConfirm } = this.state

            await userRegisterRule.validateAsync({ firstName, lastName, email, password, passwordConfirm }, {
                abortEarly: false
            });

            let snap = await db.collection('users').where('email', "==", email).get()

            if (snap.docs.length > 0) {
                toast.error('email already exist')
                this.setState({
                    loading:
                        false
                })
                return
            }

            await this.createUser()

            toast.success('Success', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
                onClose: () => {
                    this.setState({ redirect: this.props.redirect })
                }

            });


        } catch (error) {

            console.log(error.details)

            this.setState({ loading: false })

            if (error.code && error.code === "auth/weak-password") {
                
                this.showPasswordCriteria()

                this.setState({
                    errors: [
                        {
                            context: {
                                label: "password",
                                value: "",
                                key: "password"
                            },
                            message: "\"password\" is weak",
                            path: ["password"],
                            type: "string.empty",
                        }
                    ]
                })
                return
            }



            let errorMsg = "internal error contact support"

            if (error.details) {
                this.setState({ errors: error.details })
                errorMsg = 'Please check you details'
            }


            toast.error(errorMsg)

        }
    }

    isError = (filed) => {

        for (let error of this.state.errors) {
            if (error.context.key === filed) return { status: true, message: error.message }
        }

        return { status: false, message: '' }
    }

    createUser = async () => {

        let { firstName, lastName, email, password } = this.state

        let { user } = await authSecondary.createUserWithEmailAndPassword(email, password)
        authSecondary.signOut()

        await db.collection("users").doc(user.uid).set({
            firstName,
            lastName,
            email,
            isAdmin: false,
            isActive: true,
            createAt: new Date().toISOString(),
        })


    }

    showPasswordCriteria() {
        
        Swal.fire({
            html: '<div style="text-align:left">* should have a capital letter <br>* should have a number <br>* should be more than 5 characters<br>* should have a special character</div>',
        })
    }


    render() {

        let { firstName, lastName, email, password, passwordConfirm, loading, redirect } = this.state

        if (redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <div>
                <ToastContainer />
                <Form onSubmit={this.onSubmit}>
                    <Form.Group controlId="firstName" >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control isInvalid={this.isError('firstName').status} type="text" name="firstName" value={firstName} onChange={this.onChange} />
                        <Form.Control.Feedback type="invalid">
                            {this.isError('firstName').message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control isInvalid={this.isError('lastName').status} type="text" name="lastName" value={lastName} onChange={this.onChange} />
                        <Form.Control.Feedback type="invalid">
                            {this.isError('lastName').message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control isInvalid={this.isError('email').status} type="email" name="email" value={email} onChange={this.onChange} />
                        <Form.Control.Feedback type="invalid">
                            {this.isError('email').message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        {/* <Form.Control isInvalid={this.isError('password').status} type="password" name="password" value={password} onChange={this.onChange} /> */}

                        <InputGroup>
                            <Form.Control isInvalid={this.isError('password').status} type="password" name="password" value={password} onChange={this.onChange} />
                            <InputGroup.Append title="password criteria" onClick={() => this.showPasswordCriteria()}>
                                <InputGroup.Text id="basic-addon2">?</InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">
                                {this.isError('password').message}
                            </Form.Control.Feedback>
                        </InputGroup>


                    </Form.Group>
                    <Form.Group controlId="passwordConfirm">
                        <Form.Label>Re-enter password</Form.Label>
                        <Form.Control isInvalid={this.isError('passwordConfirm').status} type="password" name="passwordConfirm" value={passwordConfirm} onChange={this.onChange} />
                        <Form.Control.Feedback type="invalid">
                            {this.isError('passwordConfirm').message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={loading}>
                        Submit
                    </Button>
                </Form>
            </div>
        );
    }
}

export default RegisterForm;