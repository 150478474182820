import React, { Component } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyUploadAdapter } from '../helpers/MyUploadAdapter';
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { db } from '../services/firebase';

export default class Editor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            content : props.content,
            localContent : null,
            loading : false
        }
    }

    async save() {

        this.setState({ loading : true })
        let { localContent } = this.state

        let wait = toast.warning('Please wait', { autoClose: false })

        let settingCollection = db.collection('settings')
        await settingCollection.doc("howTo").set({
            content: localContent,
            changedAt: new Date().toISOString()
        })

        toast.update(wait, { autoClose: 1 })

        this.setState({ loading: false })

        toast.success('Saved')
    }

    render() {

        let { content, loading } = this.state

        return (
            <div>
                <Button size='sm' className="mb-2" disabled={loading} onClick={() => this.save()}>
                    Save
                </Button>
                <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        this.props.onContentChange(data)
                        this.setState({ localContent : data })
                    }}
                    onReady={editor => {
                        editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                            return new MyUploadAdapter(loader);
                        };
                    }}
                />
            </div>
        )
    }
}
