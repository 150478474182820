const Joi = require('joi')

const createCurrency = Joi.object({
    name: Joi.string().required(),
    code: Joi.string().required(),
})

const updateCurrency = Joi.object({
    name: Joi.string().required(),
    code: Joi.string().required(),
})

export {
    createCurrency,
    updateCurrency
}