import React, { Component } from 'react'
import { Card, Row, Col, InputGroup, FormControl, Button, ButtonGroup} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faToggleOn, faToggleOff, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../services/firebase"
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Link } from 'react-router-dom';

class List extends Component {

    state = {
        catList: [],
        searchList: [],
        searchKey: "",
        loadingStatueActive: false,
        loading : true
    }

    async getCountryList () {

        this.setState({loading : true})

        try {
            let snap = await db.collection('countries').orderBy('createAt', "desc").get()
            this.setState({ catList: snap.docs })

            let searchable = []

            searchable = snap.docs.map(el => {

                let something = el.data()
                something.uid = el.id

                return something

            })

            this.setState({ searchList: searchable })
        } catch (error) {
            console.log(error);
        }

        this.setState({
            loading: false
        })

    }

    componentDidMount () {
        this.getCountryList()
    }

    searchCountry = (e) => {


        let { searchKey, catList } = this.state

        let results = catList.filter(country => {
            let { name } = country.data()
            return name.toLowerCase().includes(searchKey) 
        })

        let searchable = results.map(el => {
            return {
                uid: el.id,
                ...el.data()
            }

        })

        console.log(searchable);

        this.setState({ searchList: searchable })
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) this.searchCountry()

        if (e.keyCode === 27) {
            this.setState({ searchKey: '' })

            let searchable = this.state.catList.map(el => {
                return {
                    ...el.data(),
                    id: el.id,
                }

            })

            this.setState({ searchList: searchable })
        }
    }

    columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Currency',
            selector: 'currencyCode',
            sortable: true,
        },
        {
            name: 'Labour rates',
            selector: 'labourRate',
            sortable: true,
        },
        {
            name: 'Ind ratio',
            selector: 'indRatio',
            sortable: true,
        },
        {
            name: 'Elec (Kwh)',
            selector: 'elec',
            sortable: true,
        },
        {
            name: 'Factory cost per m^2 / year',
            selector: 'factoryCost',
            sortable: true,
        },
        {
            name: 'Interest rates',
            selector: 'interestRates',
            sortable: true,
        },
        {
            name: 'Machine insurance %',
            selector: 'insurance',
            sortable: true,
        },
        {
            name: 'Shift premium # 1 shift',
            selector: 'shift1Premium',
            sortable: true,
        },
        {
            name: 'Shift premium # 2 shift',
            selector: 'shift2Premium',
            sortable: true,
        },
        {
            name: 'Shift premium # 3 shift',
            selector: 'shift3Premium',
            sortable: true,
        },
        {
            name: 'Active',
            cell: row => {

                return row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />

            },
        },
        {
            name: "Action",
            cell: row => {

                return <ButtonGroup>
                    <Button size="sm" title="change status" onClick={() => { this.changeStatus(row) }} >
                        {row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}
                    </Button>
                    <Button size="sm" variant="success" title="edit country" onClick={() => { this.updateCat(row) }} >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button size="sm" variant="danger" title="delete category" onClick={() => { this.deleteCountry(row) }} >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </ButtonGroup>

            },
            ignoreRowClick: true,
            allowOverflow: true,
        }
    ]

    updateCat = (row) => {
        this.props.history.push('/country-update', {country: row})
    }

    changeStatus = async (row) => {

        let wait = toast.warning("Please wait!")

        try {

            if(!row.isActive) {

                let snap = await db.collection('currencies').doc(row.currencyRef).get()

                if(!snap.exists) {
                    toast.update(wait, { autoClose: 1 })

                    toast.error('can not update status as the currency for the country has been removed')

                    return
                }
            }


            await db.collection("countries").doc(row.uid).update({
                isActive: !row.isActive
            })

            await this.getCountryList()

            toast.success("success");

        } catch (error) {

            console.log(error);

            toast.error("you don't have enough permission");

        }

        toast.update(wait, {autoClose : 1})

    }

    deleteCountry = async (row) => {

        let result = await Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Delete`,
        })

        if (result.value === true) {
            let wait = toast.warning("please wait");

            try {

                await db.collection("countries").doc(row.uid).delete()

                await this.getCountryList()

                toast.update(wait, {
                    autoClose: 1
                })

                toast.success("success");

            } catch (error) {

                console.log(error);

                toast.error("you don't have enough permission");

            }

            toast.update(wait, {
                autoClose: 1
            })

        }

    }

    checkDependency = async (id) => {

        let machines = await db.collection('machines').where('countryRef', '==', id).where('isActive', '==', true).get()

        return machines.docs.length > 0

    }

    onChange = (e) => {
        this.setState({ searchKey: e.target.value })
    }

    render() {
        return (
            <>
                <ToastContainer/>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                Currencies
                            </Col>
                            <Col className="text-right">
                                <Button size="sm" as={Link} to="/country-create" title="create country">
                                    < FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        onChange={this.onChange}
                                        onKeyDown={this.onKeyDown}
                                        placeholder="search countries"
                                        aria-label="search countries"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Append>
                                        <Button variant="success" size="x" onClick={() => this.searchCat()}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <DataTable
                                    pagination={true}
                                    columns={this.columns}
                                    data={this.state.searchList}
                                    progressPending={this.state.loading}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
            
        );
    }
}

export default List;