import { toast } from "react-toastify";
import { FETCH_MACHINES } from "../actionType"
import { db } from "../services/firebase"

export async function fetchMachines(categoryId = null) {

    let wait  = toast.warning('Please wait!')
    
    let machines = [];
    let machineRef =  db.collection('machines')

    if (categoryId !== null) {
        machineRef = machineRef.where('categoryRef', '==', categoryId)
    }
    
    let machineSnap = await machineRef.where('isActive', '==', true).get()

    machineSnap.docs.forEach(machine => machines.push({
        id : machine.id,
        ...machine.data()
    }))
    
    toast.update(wait, {autoClose : 1})

    return {
        type: FETCH_MACHINES,
        payload : machines
    }


}

 