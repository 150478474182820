const Joi = require('joi')

const userRegisterRule = Joi.object({
    firstName: Joi.string().required().label("first name"),
    lastName: Joi.string().required().label("last name"),
    email: Joi.string().required().email({
        tlds: {
            allow: false
        }
    }),
    password: Joi.string().required().min(5),
    passwordConfirm: Joi.any().valid(Joi.ref('password')).required().messages({
        'any.only': 'password did not match'
    })
})

const userLoginRule = Joi.object({
    email: Joi.string().required().email({
        tlds: {
            allow: false
        }
    }),
    password: Joi.string().required().min(5),
})

const userResetPassword = Joi.object({
    email: Joi.string().required().email({
        tlds: {
            allow: false
        }
    })
})

export {
    userRegisterRule,
    userLoginRule,
    userResetPassword
}