import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { db, storage } from '../services/firebase'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useAuth } from '../contexts/AuthContext'
import { addMachine, updateMachine } from '../validation/machine'
import { Form, InputGroup, Row, Col, Button, Image } from 'react-bootstrap';
import Compressor from 'compressorjs';

export default function MachineForm(props) {

    const [currencies, setCurrencies] = useState([])
    const [categories, setCategories] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        image: '',
        currencyCode: '',
        categoryName: '',
        categoryRef: '',
        investmentCost: '',
        installationCost: '',
        amortisationPeriod: '',
        residualValue: '',
        floorSpace: '',
        maintenance: '',
        elecUsed: '',
        availability: 0,
        performance: 0,
        quality: 0,
        oee: '',

    })
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { currentUser } = useAuth()
    const [image, setImage] = useState('')

    useEffect(() => {

        getCurrencies()
        getCategories()

        document.addEventListener("wheel", function (event) {
            if (document.activeElement.type === "number") {
                document.activeElement.blur();
            }
        });

    }, [])

    useEffect(() => {

        if (currencies.length) {

            if (props.update) {


                let selectedCurrency = currencies.filter(cr => {
                    return cr.id === props.updateData.currencyRef
                })

                let selectedCategory = categories.filter(cat => {
                    return cat.id === props.updateData.categoryRef
                })


                if (selectedCategory.length === 0 || selectedCurrency.length === 0) {


                    let localFormData = JSON.stringify(props.updateData)

                    localFormData = JSON.parse(localFormData)

                    localFormData.currencyCode = selectedCurrency.length === 0 ? "" : props.updateData.currencyCode
                    localFormData.currencyRef = selectedCurrency.length === 0 ? "" : props.updateData.currencyRef
                    localFormData.categoryName = selectedCategory.length === 0 ? "" : props.updateData.categoryName
                    localFormData.categoryRef = selectedCategory.length === 0 ? "" : props.updateData.categoryRef
                    


                    setFormData(localFormData)
                }

                if (selectedCategory.length && selectedCurrency.length) {
                    setFormData(props.updateData)
                }

                
            }

        }

    }, [currencies, categories, props.update, props.updateData])


    async function getCurrencies() {

        let waiting = toast.warning("Please wait! data is loading")

        let snap = await db.collection("currencies").where('isActive', '==', true).get()
        let localCurrencies = []

        snap.docs.forEach(currency => {
            let {
                name,
                code
            } = currency.data()

            localCurrencies.push({
                name,
                code,
                id: currency.id
            })
        })

        setCurrencies(localCurrencies)

        toast.update(waiting, {
            autoClose: 1
        })
    }

    async function getCategories() {

        let waiting = toast.warning("Please wait! data is loading")

        let snap = await db.collection("categories").where('isActive', '==', true).get()
        let localCat = []

        snap.docs.forEach(cat => {
            let {
                name
            } = cat.data()

            localCat.push({
                name,
                id: cat.id
            })
        })

        setCategories(localCat)

        toast.update(waiting, {
            autoClose: 1
        })
    }

    async function onSubmit(e) {

        e.preventDefault()
        setLoading(true)
        setErrors([])

        try {
            if (!props.update) {
                await createMachine()
            }

            if (props.update) {
                await editMachine()
            }

        } catch (error) {

            console.log(error);


            let errorMsg = "you don't have enough permission"

            if (error.details) {
                setErrors(error.details)
                errorMsg = 'Please check you details'
            }


            toast.error(errorMsg);

        }

        setLoading(false)

    }

    async function editMachine() {
        let imageLocal = formData.image

        if (image !== '') {
            imageLocal = await uploadImage()
        }

        await updateMachine.validateAsync({
            ...formData
        }, {
            abortEarly: false
        });

        await db.collection('machines').doc(formData.uid).update({
            ...formData,
            image: imageLocal,
            updateAt: new Date().toISOString()
        })

        redirect()
    }

    async function createMachine() {

        await addMachine.validateAsync(formData, {
            abortEarly: false
        });

        let isActive = currentUser.uid === process.env.REACT_APP_ADMIN_UID
        let userRef = currentUser.uid
        let imageLocal = ''

        if (image !== '') { 
            imageLocal = await uploadImage()
        }

        await db.collection('machines').add({
            ...formData,
            isActive,
            userRef,
            image: imageLocal,
            createAt: new Date().toISOString()
        })

        redirect()
    }

    async function uploadImage() {

        let filename = `${new Date().toISOString()}_${Math.random()}`
        let resultUpload = await storage.ref(filename).put(image)
        return await resultUpload.ref.getDownloadURL()
    }


    function isError(filed) {

        for (let error of errors) {
            if (error.context.key === filed) return {
                status: true,
                message: error.message
            }
        }

        return {
            status: false,
            message: ''
        }
    }

    function redirect() {

        let msg = `machine ${props.update ? 'updated' : 'added'} successfully`

        msg = currentUser.uid !== process.env.REACT_APP_ADMIN_UID ? msg + " and awaiting for admin approval" : msg

        Swal.fire({
            title: "Success",
            text: msg,
            type: 'success'
        }).then(result => {

            if (result.value) history.push('/machine')

        })
    }

    function onChange(e) {
        let { name, value } = e.target

        let localFormData = JSON.stringify(formData)

        localFormData = JSON.parse(localFormData)

        localFormData[name] = value



        if (name === 'currencyCode') {

            let selectedCurrency = currencies.filter(cr => {
                return cr.code === value
            })

            localFormData.currencyCode = (selectedCurrency[0]).code
            localFormData.currencyRef = (selectedCurrency[0]).id
        }

        if (name === 'categoryName') {

            let selectedCategory = categories.filter(cr => {
                return cr.name === value
            })

            localFormData.categoryName = (selectedCategory[0]).name
            localFormData.categoryRef = (selectedCategory[0]).id
        }


        setFormData(localFormData)


        if (['availability', 'performance', 'quality'].includes(name)) {

            let availability = localFormData.availability ? parseFloat(localFormData.availability) : 0
            let performance = localFormData.performance ? parseFloat(localFormData.performance) : 0
            let quality = localFormData.quality ? parseFloat(localFormData.quality) : 0

            localFormData.oee = Math.round(availability / 100 * performance / 100 * quality / 100 * 100)
            setFormData(localFormData)
        }
    }

    async function onImageChange(e) {

        let {files } = e.target

        if (files[0]) {

            if (!files[0].type.includes('image')) {
                toast.error('invalid file type please add a jpg, png or gif')
                setImage(null)
                return
            }

            new Compressor(files[0], {
                quality: 0.7,
                success: async (compressed) => {
                    setImage(compressed)
                }
            })


        } else {
            setImage(null)
        }
    }

    const viewImage = () => {

        if (props.update && formData.image) {
            return (
                <Row className="mb-4">
                    <Row>
                        <Col md="12">
                            <Image alt="" src={formData.image} thumbnail id="imgTag"  />
                        </Col>
                        <Col className="mt-2">
                            <Button size="sm" variant="danger" onClick={removeImage} id="imgRemoveBtn">remove image</Button>
                        </Col>
                    </Row>
                </Row>
            )
        }
    }

    function removeImage () {

        let localFormData = JSON.stringify(props.updateData)

        localFormData = JSON.parse(localFormData)

        localFormData.image = ""

        setFormData(localFormData)

    }

    return (
        <div>
            <ToastContainer />

            <Form onSubmit={onSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control isInvalid={isError('name').status} type="text" value={formData.name || ''} name="name" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('name').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="currency">
                            <Form.Label>Currency</Form.Label>
                            <Form.Control isInvalid={isError('currencyCode').status} as="select" name="currencyCode" value={formData.currencyCode || ''} onChange={onChange}>
                                <option disabled={true} value={""}>--select a currency--</option>
                                {
                                    currencies.map(currency => {
                                        return <option key={currency.id} value={currency.code} >{currency.code}</option>
                                    })
                                }

                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                {isError('currencyCode').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId="category">
                            <Form.Label>Category</Form.Label>
                            <Form.Control isInvalid={isError('categoryName').status} as="select" name="categoryName" value={formData.categoryName || ''} onChange={onChange}>
                                <option disabled={true} value={""}>--select a category--</option>
                                {
                                    categories.map(cat => {
                                        return <option key={cat.id} value={cat.name} >{cat.name}</option>
                                    })
                                }

                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                {isError('categoryName').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="investmentCost">
                            <Form.Label>Investment Cost</Form.Label>
                            <Form.Control isInvalid={isError('investmentCost').status} type="number" value={formData.investmentCost || ''} name="investmentCost" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('investmentCost').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Form.Group controlId="name">
                            <Form.Label>Description</Form.Label>
                            <Form.Control isInvalid={isError('description').status} as="textarea" row={3} value={formData.description || ''} name="description" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('description').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>


                    <Col md={6}>
                        <Form.Group controlId="installationCost">
                            <Form.Label>Installation Cost</Form.Label>
                            <Form.Control isInvalid={isError('installationCost').status} type="number" value={formData.installationCost || ''} name="installationCost" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('installationCost').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="amortisationPeriod">
                            <Form.Label>Amortisation Period  (years)</Form.Label>
                            <Form.Control isInvalid={isError('amortisationPeriod').status} type="number" value={formData.amortisationPeriod || ''} name="amortisationPeriod" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('amortisationPeriod').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>



                <Row>


                    <Col md={6}>
                        <Form.Group controlId="residualValue">
                            <Form.Label>Residual Value</Form.Label>
                            <Form.Control isInvalid={isError('residualValue').status} type="number" value={formData.residualValue || ''} name="residualValue" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('residualValue').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="floorSpace">
                            <Form.Label>Floor Space</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={isError('floorSpace').status} type="number" value={formData.floorSpace || ''} name="floorSpace" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">m<sup>2</sup></InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('floorSpace').message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                </Row>

                <Row>

                    <Col md={6}>
                        <Form.Group controlId="quality">
                            <Form.Label>Maintenance</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={isError('maintenance').status} type="number" value={formData.maintenance || ''} name="maintenance" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('maintenance').message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="elecUsed">
                            <Form.Label>Elec used</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={isError('elecUsed').status} type="number" value={formData.elecUsed || ''} name="elecUsed" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">(kWh)</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('elecUsed').message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                </Row>

                <Row>

                    <Col md={3}>
                        <Form.Group controlId="availability">
                            <Form.Label>Availability</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={isError('availability').status} type="number" value={formData.availability || ''} name="availability" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('availability').message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="performance">
                            <Form.Label>Performance</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={isError('performance').status} type="number" value={formData.performance || ''} name="performance" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('performance').message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="quality">
                            <Form.Label>Quality</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={isError('quality').status} type="number" value={formData.quality || ''} name="quality" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('quality').message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="oee">
                            <Form.Label>OEE</Form.Label>
                            <InputGroup>
                                <Form.Control isInvalid={isError('oee').status} disabled={true} type="number" value={formData.oee || ''} name="oee" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('oee').message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="img">
                            <Form.Label>Image</Form.Label>
                            <Form.Control accept="image/*" type="file" name="image" hidden={props.update && formData.image} onChange={onImageChange} />
                        </Form.Group>
                    </Col>
                </Row>

                {viewImage()}


                <Button variant="primary" type="submit" disabled={loading}>
                    Submit
                </Button>
            </Form>
        </div>
    )
}
