import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import CountryForm from '../../components/CountryForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Create() {    

    const history = useHistory()

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col xs={4} md={4}>
                        <Button size="sm" variant="warning" as={Link} to="/country" title="back">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                    <Col className="align-items-center">
                        Create country
                    </Col>
                </Row>

            </Card.Header>
            <Card.Body>
                <CountryForm update={false} history={history} statusChange={false} />
            </Card.Body>
        </Card>
    )
}