import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-toastify/dist/ReactToastify.css';
import MyNav from './components/MyNav';
import './App.css';
import 'sweetalert2/dist/sweetalert2.css'
import { AuthProvider } from "./contexts/AuthContext"
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <HelmetProvider>
          <Helmet>
            <meta
              name="description"
              content="Free calculator to get an hourly cost rate for your manufacturing process. Labour and machine costs breakdown in numbers and graphically.Change the inputs to suit your scenario" />
          </Helmet>
          <MyNav></MyNav>
        </HelmetProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
