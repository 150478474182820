import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Link,
    Route,
} from "react-router-dom";

import Calculator from '../routers/Calculator';
import Register from '../routers/Register';

import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import Login from '../routers/Login';
import AuthMenuItem from '../components/AuthMenuItem';
import AdminRoute from '../components/AdminRoute';
import PrivateRoute from '../components/PrivateRoute';
import GuestRoute from '../components/GuestRoute';
import UserList from '../routers/Users/List';
import UserCreate from '../routers/Users/Create';
import { useAuth } from '../contexts/AuthContext';
import CategoryCreate from "../routers/Categories/Create"
import CategoryList from "../routers/Categories/List"
import CategoryUpdate from "../routers/Categories/Update"
import CurrencyList from "../routers/Currency/List"
import CurrencyCreate from "../routers/Currency/Create"
import CurrencyUpdate from "../routers/Currency/Update"
import ExchangeRates from '../routers/Currency/ExchangeRates';
import ForgotPassword from '../routers/ForgotPassword';

import CountryList from "../routers/Country/List"
import CountryCreate from "../routers/Country/Create"
import CountryUpdate from "../routers/Country/Update"

import MachineList from "../routers/Machine/List"
import MachineCreate from "../routers/Machine/Create"
import MachineUpdate from "../routers/Machine/Update"
import MachineImport from "../routers/Machine/ImportMachine"
import MachineImages from "../routers/Machine/Images"
import HowTo from '../routers/HowTo';

export default function MyNav() {

    const { currentUser } = useAuth()
    const isAdmin = currentUser !== null && currentUser.uid === process.env.REACT_APP_ADMIN_UID
    const isUser = currentUser !== null && currentUser.uid !== process.env.REACT_APP_ADMIN_UID


    const AdminMenuItems = isAdmin && (
        <>
            <NavDropdown className="my-nav-item" title="Users" id="basic-nav-dropdown" bg="dark">
                <NavDropdown.Item as={Link} to="/users-create">
                    Create
                </NavDropdown.Item>
                <NavDropdown.Item to="/users" as={Link}>
                    List
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="my-nav-item" title="Categories" id="basic-nav-dropdown" bg="dark">
                <NavDropdown.Item as={Link} to="/categories-create">
                    Create
                </NavDropdown.Item>
                <NavDropdown.Item to="/categories" as={Link}>
                    List
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="my-nav-item" title="Currency" id="basic-nav-dropdown" bg="dark">
                <NavDropdown.Item as={Link} to="/currency-create">
                    Create
                </NavDropdown.Item>
                <NavDropdown.Item to="/currency" as={Link}>
                    List
                </NavDropdown.Item>
                <NavDropdown.Item to="/exchange-rates" as={Link}>
                    Exchange Rate
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="my-nav-item" title="Country" id="basic-nav-dropdown" bg="dark">
                <NavDropdown.Item as={Link} to="/country-create">
                    Create
                </NavDropdown.Item>
                <NavDropdown.Item to="/country" as={Link}>
                    List
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="my-nav-item" title="Machines" id="basic-nav-dropdown" bg="dark">
                <NavDropdown.Item as={Link} to="/machine-create">
                    Create
                </NavDropdown.Item>
                <NavDropdown.Item to="/machine" as={Link}>
                    List
                </NavDropdown.Item>
            </NavDropdown>            
        </>
    )

    const UserMenuItems = isUser && (
        <>
            <NavDropdown className="my-nav-item" title="Machines" id="basic-nav-dropdown" bg="dark">
                {/*<NavDropdown.Item as={Link} to="/machine-create">
                    Create
                </NavDropdown.Item>*/}
                <NavDropdown.Item to="/machine" as={Link}>
                    List
                </NavDropdown.Item>
            </NavDropdown>  
        </>
    )



    return (
        <Router>
            <div>
                <Navbar bg="light" id="myNav" expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link to="/" as={Link} className="logo">
                                <img src="/img/logo.png" alt="logo"/>
                            </Nav.Link>
                            {AdminMenuItems}
                            {UserMenuItems}
                            <div className="my-nav-item">
                                <Nav.Link  to="/start-here" as={Link}>Start Here</Nav.Link>
                            </div>
                        </Nav>

                    </Navbar.Collapse>

                    <Navbar.Collapse className="justify-content-end">
                        <AuthMenuItem />
                    </Navbar.Collapse>
                </Navbar>

                <Container>
                    <div className="row justify-content-center mt-5">
                        <div className="col-11">
                            <Switch>
                                <GuestRoute path="/login" component={Login} />
                                <GuestRoute path="/register" component={Register} />
                                <GuestRoute path="/forgot-password" component={ForgotPassword} />
                                <AdminRoute path="/users" component={UserList} />
                                <AdminRoute path="/users-create" component={UserCreate} />

                                <AdminRoute path="/categories-create" component={CategoryCreate} />
                                <AdminRoute path="/categories" component={CategoryList} />
                                <AdminRoute path="/categories-update" component={CategoryUpdate} />

                                <AdminRoute path="/currency" component={CurrencyList} />
                                <AdminRoute path="/currency-create" component={CurrencyCreate} />
                                <AdminRoute path="/currency-update" component={CurrencyUpdate} />
                                <AdminRoute path="/exchange-rates" component={ExchangeRates} />

                                <AdminRoute path="/country" component={CountryList} />
                                <AdminRoute path="/country-create" component={CountryCreate} />
                                <AdminRoute path="/country-update" component={CountryUpdate} />

                                <PrivateRoute path="/machine" component={MachineList} />
                                <AdminRoute path="/machine-create" component={MachineCreate} />
                                <AdminRoute path="/machine-update" component={MachineUpdate} />  
                                <AdminRoute path="/machine-import" component={MachineImport} />
                                <AdminRoute path="/machine-images" component={MachineImages} />

                                <Route path="/start-here" component={HowTo} />

                                <Route path="/" component={Calculator} />
                            </Switch>
                        </div>
                    </div>

                </Container>
            </div>
        </Router>
    )
}
