const Joi = require('joi')

const addMachine = Joi.object({
    name: Joi.string().required(),
    description: Joi.string(),
    currencyCode: Joi.string().required().label('currency'),
    categoryName: Joi.string().required().label('category'),
    investmentCost: Joi.number().required().label('investment cost'),
    installationCost: Joi.number().required().label('installation cost'),
    amortisationPeriod: Joi.number().required().label('amortisation period'),
    residualValue: Joi.number().required().label("residual value"),
    floorSpace: Joi.number().required().label('floor space'),
    maintenance: Joi.number().required(),
    elecUsed: Joi.number().required().label('elec used'),
    availability: Joi.number().greater(0).required(),
    performance: Joi.number().greater(0).required(),
    quality: Joi.number().greater(0).required(),
}).options({
    allowUnknown: true
})

const updateMachine = Joi.object({
    name: Joi.string().required(),
    description: Joi.string(),
    currencyCode: Joi.string().required(),
    investmentCost: Joi.number().required(),
    installationCost: Joi.number().required(),
    amortisationPeriod: Joi.number().required(),
    residualValue: Joi.number().required(),
    floorSpace: Joi.number().required(),
    maintenance: Joi.number().required(),
    elecUsed: Joi.number().required(),
    availability: Joi.number().required(),
    performance: Joi.number().required(),
    quality: Joi.number().required(),
}).options({
    allowUnknown: true
})

export {
    addMachine,
    updateMachine
}