import React from 'react'
import CategoryForm from '../../components/CategoryForm'
import { Card, Button,Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

export default function Create() {

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col xs={4} md={4}>
                        <Button size="sm" variant="warning" as={Link} to="/categories" title="back">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                    <Col className="align-items-center">
                        Create Category
                    </Col>
                </Row>
               

            </Card.Header>
            <Card.Body>
                <CategoryForm update={false} />
            </Card.Body>
        </Card>
    )
}