import React from 'react'
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext';


export default function AuthMenuItem() {

    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function logoutUser() {
        await logout()
        history.push('login')
    }

    if (!currentUser) {

        return (
            <Navbar.Text>
                <Link style={{ fontWeight : 500 }} to="/login">Login</Link>
            </Navbar.Text>
        )

    }


    return (
        <Nav style={{ marginRight: "4rem" }} id="test">
            <NavDropdown title={currentUser.email} bg="dark">
                <NavDropdown.Item onClick={logoutUser}>
                    Logout
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    )

}
