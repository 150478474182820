
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Card, Row, Col, Button, Table, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import XLSX from 'xlsx'
import AuthContext from "../../contexts/AuthContext"
import { db } from '../../services/firebase';
import { ToastContainer, toast } from 'react-toastify';

const machineProperties = {
    categoryName: "category",
    name: "machine",
    description: "description",
    currencyCode: "currency",
    investmentCost: "investment cost",
    installationCost: "installation cost",
    amortisationPeriod: "amortisation period (years)",
    residualValue: "residual value",
    floorSpace: "floor space (m^2)",
    maintenance: "maintenance",
    elecUsed: "elec used / hour (Kwh)",
    availability: "availability %",
    performance: "performance %",
    quality: "quality %",
    oee: "OEE %",
}

class ImportMachine extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            machines: [],
            preparedData: {
                updatedItems: [],
                newItems: []
            },
            loading: false
        }
    }

    uploadFile() {
        document.getElementById("file").click()
    }

    readExcel(event) {
        let selectedFile = event.target.files[0];

        console.log(selectedFile);

        if (selectedFile) {

            let fileReader = new FileReader();

            fileReader.onload = (event) => {
                let data = event.target.result;
                let workbook = XLSX.read(data, { type: 'binary' });

                let machines = XLSX.utils.sheet_to_json(workbook.Sheets['Sheet 1'])
                this.setState({ machines })
                this.prepareData()
            }

            fileReader.readAsBinaryString(selectedFile)
        }
    }

    async prepareData() {

        this.setState({
            preparedData: {
                updatedItems: [],
                newItems: []
            }
        })

        let wait = toast.warning("Please wait! data is loading", { autoClose: 500000 })

        for (let machine of this.state.machines) {

            if (machine.Updated !== undefined && machine.Updated.toLowerCase() === 'yes') {
                let categoryRef = await this.createCategoryIfNotExist(machine[machineProperties.categoryName])

                let currencyRef = await this.createCurrencyIfNotExist(machine[machineProperties.currencyCode])

                let machineData = { id: machine.id, categoryRef, currencyRef }

                for (let property in machineProperties) {
                    machineData[property] = machine[machineProperties[property]]
                }

                let localPrepareData = this.state.preparedData

                localPrepareData.updatedItems.push(machineData)

                this.setState({ preparedData: localPrepareData })
            }


            if (machine.id === undefined) {
                let categoryRef = await this.createCategoryIfNotExist(machine[machineProperties.categoryName])

                let currencyRef = await this.createCurrencyIfNotExist(machine[machineProperties.currencyCode])

                let { currentUser } = this.context

                let machineData = { categoryRef, currencyRef, userRef: currentUser.uid }

                for (let property in machineProperties) {
                    machineData[property] = machine[machineProperties[property]]
                }

                let localPrepareData = this.state.preparedData

                localPrepareData.newItems.push(machineData)

                this.setState({ preparedData: localPrepareData })
            }
        }

        toast.update(wait, { autoClose: 1 })
    }

    async createCategoryIfNotExist(categoryName) {

        let categoryCollection = await db.collection('categories').where('name', '==', categoryName).get()

        if (!categoryCollection.empty) {
            return categoryCollection.docs[0].id
        }

        let category = await db.collection('categories').add({ name: categoryName, isActive: true, createAt: new Date().toISOString() })

        return category.id

    }

    async createCurrencyIfNotExist(currencyCode) {

        let currencyCollection = await db.collection('currencies').where('code', '==', currencyCode).get()

        if (!currencyCollection.empty) {
            return currencyCollection.docs[0].id
        }

        let currency = await db.collection('currencies').add({ code: currencyCode, name: currencyCode, isActive: true, createAt: new Date().toISOString() })

        return currency.id

    }

    tableRows(type, machine) {
        let typeRow = <Badge className=" bg-success">update</Badge>
        if (type === 'new') {
            typeRow = <Badge className=" bg-warning">new</Badge>
        }

        return (
            <tr key={machine.name}>
                <td>
                    {typeRow}
                </td>
                <td>
                    {machine.name}
                </td>
                <td>
                    {machine.description}
                </td>
                <td>
                    {machine.currencyCode}
                </td>
                <td>
                    {machine.categoryName}
                </td>
                <td>
                    {machine.investmentCost}
                </td>
                <td>
                    {machine.installationCost}
                </td>
                <td>
                    {machine.amortisationPeriod}
                </td>
                <td>
                    {machine.residualValue}
                </td>
                <td>
                    {machine.floorSpace}
                </td>
                <td>
                    {machine.maintenance}
                </td>
                <td>
                    {machine.elecUsed}
                </td>
                <td>
                    {machine.availability}
                </td>
                <td>
                    {machine.performance}
                </td>
                <td>
                    {machine.quality}
                </td>
                <td>
                    {machine.oee}
                </td>
            </tr>
        )
    }

    async syncData() {

        let { preparedData } = this.state

        if (preparedData.newItems.length === 0 && preparedData.updatedItems.length === 0) {
            toast.error("no machines changes")
            return
        }

        let wait = toast.warning("Please wait!", { autoClose: false })

        this.setState({ loading: true })

        let batch = db.batch()

        for (let machine of preparedData.newItems) {
            let machineRef = db.collection('machines').doc()
            batch.set(machineRef, { ...machine, createAt: new Date().toISOString(), isActive: true })
        }

        for (let machine of preparedData.updatedItems) {
            let machineRef = db.collection('machines').doc(machine.id)
            batch.update(machineRef, machine)
        }

        await batch.commit()

        toast.update(wait, { autoClose: 1 })

        toast.success("machines sync successfully")
    }

    render() {

        let { preparedData, loading } = this.state

        return (
            <div>
                <ToastContainer />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={4} md={4}>
                                <Button size="sm" variant="warning" as={Link} to="/machine" title="back">
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                            </Col>
                            <Col className="align-items-center">
                                Machine Import
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Button onClick={() => this.uploadFile()}>
                                    Import Excel
                                </Button>
                                <input type="file" accept=".xls,.xlsx" hidden id="file" onChange={(e) => this.readExcel(e)} />
                            </Col>
                            <Col className="text-right">
                                <Button onClick={() => this.syncData()} variant="success" disabled={loading}>
                                    Sync
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-3">
                                <Table responsive striped className="x-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Type
                                            </th>
                                            <th>
                                                Machine
                                            </th>
                                            <th>
                                                Description
                                            </th>
                                            <th>
                                                Currency
                                            </th>
                                            <th>
                                                Category
                                            </th>
                                            <th>
                                                Investment cost
                                            </th>
                                            <th>
                                                Installation cost
                                            </th>
                                            <th>
                                                Amortisation period (years)
                                            </th>
                                            <th>
                                                Residual value
                                            </th>
                                            <th>
                                                Floor space
                                            </th>
                                            <th>
                                                Maintenance
                                            </th>
                                            <th>
                                                Elec used / hour (Kwh)
                                            </th>
                                            <th>
                                                Availability
                                            </th>
                                            <th>
                                                Performance
                                            </th>
                                            <th>
                                                Quality
                                            </th>
                                            <th>
                                                OEE
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {preparedData.updatedItems.map((machine, index) => {

                                            return this.tableRows('update', machine)

                                        })}

                                        {preparedData.newItems.map((machine, index) => {

                                            return this.tableRows('new', machine)

                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default ImportMachine;