export const options = {
    maintainAspectRatio: false,
    // responsive: true,
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                var total = meta.total;
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                return percentage + '%';
            },
            title: function (tooltipItem, data) {
                //console.log(data.labels[tooltipItem[0].index] );
                return "";
            },

        },
        mode: "index"
    },
    showAllTooltips: true,
    legend: {
        onClick: (e) => e.stopPropagation(),
         align: "start",
    }
}

export const barChartOptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var currentValue = dataset.data[tooltipItem.index];

                return parseFloat(currentValue).toFixed(2);
            },
            title: function (tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
            },

        },
    },
}

export const initShowTooltipsAlwaysPlugin = () => {
    // Chart.pluginService.register({
    //     beforeRender: function (chart) {
    //         if (chart.config.options.showAllTooltips) {
    //             chart.pluginTooltips = [];
    //             chart.config.data.datasets.forEach(function (dataset, i) {
    //                 chart.getDatasetMeta(i).data.forEach(function (sector, j) {
    //                     chart.pluginTooltips.push(new Chart.Tooltip({
    //                         _chart: chart.chart,
    //                         _chartInstance: chart,
    //                         _data: chart.data,
    //                         _options: chart.options.tooltips,
    //                         _active: [sector]
    //                     }, chart));
    //                 });
    //             });
    //             // turn off normal tooltips
    //             chart.options.tooltips.enabled = false;
    //         }
    //     },
    //     afterDraw: function (chart, easing) {
    //         if (chart.config.options.showAllTooltips) {
    //             // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
    //             if (!chart.allTooltipsOnce) {
    //                 if (easing !== 1) return;
    //                 chart.allTooltipsOnce = true;
    //             }
    //             chart.options.tooltips.enabled = true;
    //             Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
    //                 tooltip.initialize();
    //                 tooltip.update(); // we don't actually need this since we are not animating tooltips
    //                 tooltip.pivot();
    //                 tooltip.transition(easing).draw();
    //             });
    //             chart.options.tooltips.enabled = false;
    //         }
    //     }
    // });

    // Chart.Tooltip.positioners.nearest = function (elements, position) {
    //     console.log(elements);

    //     if (!elements.length) {
    //         return false;
    //     }
    //     var offset = 0;
    //     //adjust the offset left or right depending on the event positio
    //     // if (elements[0]._chart.width / 2 > position.x) {
    //     //     offset = 20;
    //     // } else {
    //     //     offset = -20;
    //     // }
    //     return {
    //         x: 0,
    //         y: 0
    //     }
    // }   


}