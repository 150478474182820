import machine from './machine'
import calculation from './calculation'
import metaData from './metaData'
import {combineReducers} from 'redux'

const allReducers = combineReducers({
    machine,
    metaData,
    calculation
})

export default allReducers