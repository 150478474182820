import React, { Component } from 'react'
import { Card, Row, Col, InputGroup, FormControl, Button, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faToggleOn, faToggleOff, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../services/firebase"
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

class List extends Component {

    state = {
        catList: [],
        searchList: [],
        searchKey: "",
        loadingStatueActive: false,
        loading: true
    }

    getCatList = async () => {

        this.setState({ loading: true })

        try {
            let snap = await db.collection('categories').orderBy('createAt', "desc").get()
            this.setState({ catList: snap.docs })
            let searchable = snap.docs.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })
            this.setState({ searchList: searchable })
        } catch (error) {
            console.log(error);
        }

        this.setState({ loading: false })

    }

    componentDidMount() {
        this.getCatList()
    }

    searchCat = (e) => {


        let { searchKey, catList } = this.state

        let results = catList.filter(category => {
            let { name } = category.data()
            return name.toLowerCase().includes(searchKey)
        })

        let searchable = results.map(el => {
            return {
                uid: el.id,
                ...el.data()
            }

        })

        console.log(searchable);

        this.setState({ searchList: searchable })
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) this.searchCat()

        if (e.keyCode === 27) {
            this.setState({ searchKey: '' })

            let searchable = this.state.catList.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })

            this.setState({ searchList: searchable })
        }
    }

    columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Active',
            cell: row => {

                return row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />

            },
        },
        {
            name: "Action",
            cell: row => {

                return <ButtonGroup>
                    <Button size="sm" title="change status" disabled={this.state.loadingStatueActive} onClick={() => { this.changeStatus(row) }} >
                        {row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}
                    </Button>
                    <Button size="sm" variant="success" title="edit category" onClick={() => { this.updateCat(row) }} >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button size="sm" variant="danger" title="delete category" onClick={() => { this.deleteCategory(row) }} >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </ButtonGroup>

            },
            ignoreRowClick: true,
            allowOverflow: true,
        }
    ]

    deleteCategory = async (row) => {
        let wait = toast.warning("please wait");

        try {

            if (await this.checkDependency(row.uid)) {
                toast.error("can not delete this category, has active machines under this category");
                return
            }

            await db.collection("categories").doc(row.uid).delete()

            await this.getCatList()

            toast.update(wait, { autoClose: 1 })

            toast.success("success");

        } catch (error) {

            console.log(error);

            toast.error("you don't have enough permission");

        }

        toast.update(wait, { autoClose: 1 })

    }

    updateCat = (row) => {
        this.props.history.push('/categories-update', { cat: row })
    }

    checkDependency = async (id) => {

        let machines = await db.collection('machines').where('categoryRef', '==', id).where('isActive', '==', true).get()

        return machines.docs.length > 0

    }

    changeStatus = async (row) => {

        let wait = toast.warning("please wait");

        try {

            await db.collection("categories").doc(row.uid).update({
                isActive: !row.isActive
            })

            await this.getCatList()

            toast.update(wait, { autoClose: 1 })

            toast.success("success");

        } catch (error) {


            toast.error("you don't have enough permission");

        }

        toast.update(wait, { autoClose: 1 })

    }

    onChange = (e) => {
        this.setState({ searchKey: e.target.value })
    }

    render() {
        return (
            <>
                <ToastContainer />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                Categories
                            </Col>
                            <Col className="text-right">
                                <Button size="sm" as={Link} to="/categories-create" title="create category">
                                    < FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </Col>
                        </Row>

                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        onChange={this.onChange}
                                        onKeyDown={this.onKeyDown}
                                        placeholder="search categories"
                                        aria-label="search categories"
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKey || ''}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="success" size="x" onClick={() => this.searchCat()}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <DataTable
                                    pagination={true}
                                    columns={this.columns}
                                    data={this.state.searchList}
                                    progressPending={this.state.loading}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>

        );
    }
}

export default List;