import React, { useEffect, useState } from 'react'
import {
    Form,
    Button
} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import {  useHistory } from 'react-router-dom';
import { db } from '../services/firebase';
import { createCurrency, updateCurrency } from '../validation/currency';
import Swal from 'sweetalert2/dist/sweetalert2.js'


export default function CurrencyForm(props) {
    
    const [id, setId] = useState(null)
    const [name, setName] = useState(props.update && props.updateData.name)
    const [code, setCode] = useState(props.update && props.updateData.code)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const history = useHistory()

    useEffect(() => {
        
        if (props.update) {
            setName(props.updateData.name)
            setCode(props.updateData.code)
            setId(props.updateData.uid)
        }

    }, [props])

     async function onSubmit(e) {

         e.preventDefault()

         setLoading(true)

         try {

             if(!props.update) {
                 await createNewCurrency()
             }

             if (props.update) {
                 await updateExistingCurrency()
             }


         } catch (error) {

            console.log(error);
            

             let errorMsg = "you don't have enough permission"

             if (error.details) {
                 setErrors(error.details)
                 errorMsg = 'Please check you details'
             }


             toast.error(errorMsg, {
                 position: "top-right",
                 closeOnClick: true,
                 pauseOnHover: false,
                 draggable: true,
                 progress: 0,

             });

         }

         setLoading(false)

     }


     const isError = (filed) => {
         for (let error of errors) {
             if (error.context.key === filed) return {
                 status: true,
                 message: error.message
             }
         }

         return {
             status: false,
             message: ''
         }
     }

    function onChange (e) {

        if(e.target.name === "name")  setName(e.target.value)

        if(e.target.name === "code") setCode(e.target.value)
    }

    async function createNewCurrency() {

        await createCurrency.validateAsync({
            name,
            code
        }, {
            abortEarly: false
        });


        await db.collection('currencies').add({ name, code, isActive : true, createAt: new Date().toISOString() })

        redirect()
    }

    async function updateExistingCurrency() {

        await updateCurrency.validateAsync({
            name,
            code
        }, {
            abortEarly: false
        });

        await db.collection('currencies').doc(id).update({
            name,
            code,
            updateAt: new Date().toISOString()
        })

        let batch = db.batch()

        let countries = await db.collection('countries').where('currencyRef', '==', id).get()
        let machines = await db.collection('machines').where('currencyRef', '==', id).get()

        countries.docs.forEach(country => {
            batch.update(country.ref, {currencyCode : code})
        })

        machines.docs.forEach(machine => {
            let data = machine.data()

            data.currencyCode = code

            console.log(data);
            batch.update(machine.ref, data)
        })

        await batch.commit()

        redirect()
    }

    function redirect () {

        let msg = `currency ${props.update ? 'updated' : 'added'} successfully`

        Swal.fire({
            title : "Success",
            text: msg,
            type : 'success'
        }).then(result => {

            if(result.value) history.push('/currency')

        })
    }

    return (
        <div>
            <ToastContainer />
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control isInvalid={isError('name').status} type="text" value={name || ''} name="name" onChange={onChange}/>
                        <Form.Control.Feedback type="invalid">
                            {isError('name').message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="code">
                        <Form.Label>Code</Form.Label>
                        <Form.Control isInvalid={isError('code').status} type="text" value={code || ''} name="code" onChange={onChange}/>
                        <Form.Control.Feedback type="invalid">
                            {isError('code').message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Button variant="primary" type="submit" disabled={loading}>
                        Submit
                    </Button>
                </Form>
        </div>
    )
}
