import React, { useState, useRef } from 'react'
import {
    Form,
    Button
} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import { userLoginRule } from "../validation/user"
import {  useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../services/firebase';

export default function LoginForm() {

    const emailRef  = useRef()
    const passwordRef = useRef()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const { login } = useAuth()
    const history = useHistory()

    async function onSubmit(e) {

        e.preventDefault()
        
        setLoading(true)

        try {

            let email = emailRef.current.value
            let password = passwordRef.current.value

            await userLoginRule.validateAsync({ email, password }, {
                abortEarly: false
            });

            let snap = await db.collection('users').where('email', "==", emailRef.current.value).limit(1).get()


            if (!snap.exists && (snap.docs[0]).data().isActive === false) {
                toast.error("your account has been deactivated please contact support")
                setLoading(false)
                return
            }

            await login(emailRef.current.value, passwordRef.current.value)

            history.push('/')


        } catch (error) {

            console.log(error);

            setLoading(false)

            let errorMsg = "Username or password incorrect"

            if (error.details) {
                setErrors(error.details)
                errorMsg = 'Please check you details'
            }


            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,

            });

        }


    }

    
    const isError = (filed) => {
        for (let error of errors) {
            if (error.context.key === filed) return { status: true, message: error.message }
        }

        return { status: false, message: '' }
    }


    return (
        <div>
            <div>
                <ToastContainer />
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control isInvalid={isError('email').status} type="email" name="email" ref={emailRef}/>
                        <Form.Control.Feedback type="invalid">
                            {isError('email').message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control isInvalid={isError('password').status} type="password" name="password" ref={passwordRef} />
                        <Form.Control.Feedback type="invalid">
                            {isError('password').message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={loading}>
                        Login
                    </Button>
                </Form>

            </div>
        </div>
    )
}
