import React, {
    Component
} from 'react'
import {
    db
} from '../../services/firebase'
import { Card, Table, InputGroup, FormControl, Button, Row, Col, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSync, faSyncAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import XLSX from 'xlsx'
import exportToExcel from '../../helpers/exportToExcel';

export default class ExchangeRates extends Component {

    state = {
        currencies: [],
        rates: {},
        changedRates: {},
        loading: false
    }

    getCurrencies = async () => {

        let wait = toast.warning("Please wait! data is loading")
        this.setState({ loading: true })

        let snap = await db.collection('currencies').where("isActive", "==", true).get()

        let currencies = snap.docs.map(el => {
            return {
                id: el.id,
                ...el.data()
            }
        })

        this.setState({
            currencies
        })

        toast.update(wait, { autoClose: 1 })
        this.setState({ loading: false })

    }

    getRates = async () => {
        this.setState({ loading: true })
        let wait = toast.warning("Please wait! data is loading")

        let snap = await db.collection('rates').get()

        let rates = {}

        snap.docs.forEach(el => {
            rates[el.id] = { ...el.data() }
        })

        this.setState({
            rates
        })

        toast.update(wait, { autoClose: 1 })
        this.setState({ loading: false })
    }

    componentDidMount() {
        this.getRates()
        this.getCurrencies()

        document.addEventListener("wheel", function (event) {
            if (document.activeElement.type === "number") {
                document.activeElement.blur();
            }
        });
    }

    onChange = (e) => {
        let { rates } = this.state

        let { name, value } = e.target

        rates[name] = { rate: value, hasChanged: true }

        this.setState({ rates })
    }

    updateRate = async (currency = "") => {

        this.setState({ loading: true })

        if (currency !== "") {

            let localRate = this.state.rates[currency]

            if (!localRate) {
                toast.warning("rate not found");
                this.setState({ loading: false })
                return
            }

            if (localRate.rate == 0) {

                toast.warning("rate can not be a zero value");
                this.setState({ loading: false })
                return
            }


            try {

                let { rate } = this.state.rates[currency]

                let rateRef = db.collection('rates').doc(currency)

                if ((await rateRef.get()).exists) {
                    rateRef.update({ rate, updateAt: new Date().toISOString() })
                } else {
                    rateRef.set({ rate, createAt: new Date().toISOString() })
                }

                toast.success(`exchange rate for ${currency.replace("_", " to ")} updated successfully`);

            } catch (error) {

                console.log(error);

            }


        } else {
            await this.updateAll()
        }


        this.setState({ loading: false })

    }

    updateAll = async () => {

        let { rates } = this.state

        try {

            let batch = db.batch()

            for (let index in rates) {


                let { rate, hasChanged } = rates[index]

                if (hasChanged !== true) continue

                let rateRef = db.collection('rates').doc(index)

                if ((await rateRef.get()).exists) {
                    batch.update(rateRef, { rate, updateAt: new Date().toISOString() })
                } else {
                    batch.set(rateRef, { rate, createAt: new Date().toISOString() })
                }
            }

            await batch.commit()


            toast.success(`exchange rates updated successfully`);
        } catch (error) {
            console.log(error);

            toast.error(`Oops..! something is wrong`);
        }

    }

    importExRates() {
        document.getElementById("file").click()
    }

    downloadExRate() {

        let { currencies, rates } = this.state
        let exportData = []

        currencies.forEach(currency => {

            let { code } = currency

            currencies.forEach(covertTo => {
                let { code: covertCode } = covertTo

                if (covertCode === code) return

                let currencyExchange = `${code} to ${covertCode}`
                let exRateMapName = `${code}_${covertCode}`

                let rate = rates[exRateMapName] ? rates[exRateMapName].rate : ""

                exportData.push({
                    'currency to covert to': currencyExchange,
                    'exchange rate': rate
                })

            })

        })


        exportToExcel(exportData, "exchange_rates_")
    }

    readExcel(event) {
        let selectedFile = event.target.files[0];

        let wait = toast.warning("Please wait!")

        if (selectedFile) {

            let fileReader = new FileReader();

            fileReader.onload = (event) => {
                let { rates } = this.state
                let data = event.target.result;
                let workbook = XLSX.read(data, { type: 'binary' });

                let importRates = XLSX.utils.sheet_to_json(workbook.Sheets['Sheet 1'])


                importRates.forEach(el => {
                    let conversion = el['currency to covert to']
                    let rate = el['exchange rate']
                    let formattedConversion = conversion.replace(" to ", "_")
                    let formattedRate = rates[formattedConversion]
                    if (rate) {
                        let hasChanged = false

                        if(formattedRate === undefined) hasChanged = true
                        if(formattedRate && formattedRate.rate !== rate) hasChanged = true


                        rates[formattedConversion] = { rate, hasChanged }
                    }
                })

                toast.update(wait, { autoClose: 1 })

                toast.warning("Click *update all* to save to database")

                this.setState({ rates })
            }

            fileReader.readAsBinaryString(selectedFile)
        }
    }

    render() {

        let { currencies } = this.state

        let currencyHeaders = currencies.map(currency => {
            return <th key={currency.id} title={currency.name}>{currency.code}</th>
        })


        let rates = currencies.map((currencyRow, index) => {

            let cell = currencies.map(currencyColumn => {

                let name = `${currencyRow.code}_${currencyColumn.code}`

                let rate = this.state.rates[name] ? this.state.rates[name].rate : ""


                let form = (<InputGroup style={{ width: '150px' }}>
                    <FormControl
                        title={name}
                        value={rate}
                        onChange={this.onChange}
                        type="number" name={name}
                    />
                    <InputGroup.Append>
                        <Button variant="warning" size="sm" disabled={this.state.loading} onClick={() => this.updateRate(name)} >
                            <FontAwesomeIcon icon={faSync} />
                        </Button>
                    </InputGroup.Append>
                </InputGroup>)



                return (
                    <td key={currencyColumn.id + index + 2}>
                        {currencyColumn.code !== currencyRow.code && form}
                    </td>
                )
            })

            return (<tr key={currencyRow.id + index + 3}>
                <th title={currencyRow.name}>
                    {currencyRow.code}
                </th>
                {cell}
            </tr>)
        })


        return (
            <Card>
                <ToastContainer />
                <Card.Header>Exchange Rate</Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Button variant="warning" className="mb-4" disabled={this.state.loading} onClick={() => this.updateRate("")}>
                                update all <FontAwesomeIcon icon={faSyncAlt} />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <ButtonGroup>
                                <Button size="sm" variant="success" disabled={this.state.loading} title="download exchange rate excel" onClick={() => this.downloadExRate()}>
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                                <Button size="sm" variant="success" disabled={this.state.loading} title="upload exchange rate excel" onClick={() => this.importExRates()}>
                                    <FontAwesomeIcon icon={faUpload} />
                                </Button>
                                <input type="file" name="" id="file" hidden onChange={(e) => this.readExcel(e)} />
                            </ButtonGroup>

                        </Col>
                    </Row>
                    <Table responsive striped className="x-table">
                        <thead>
                            <tr>
                                <th>currencies</th>
                                {currencyHeaders}
                            </tr>
                        </thead>
                        <tbody>
                            {rates}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }
}