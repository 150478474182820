import { faEdit, faPlus, faSearch, faToggleOff, faToggleOn, faTrash, faDownload, faUpload, faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { db, storage } from '../../services/firebase'
import { Card, Row, Col, InputGroup, FormControl, Button, ButtonGroup, Form } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Link } from 'react-router-dom'
import exportToExcel from '../../helpers/exportToExcel'
import AuthContext from "../../contexts/AuthContext"
export default class List extends Component {

    static contextType = AuthContext

    constructor() {
        super()

        this.state = {
            machineList: [],
            searchList: [],
            searchKey: '',
            ladingStatusActive: false,
            loading: true,
            status: '',
            open: false
        }
    }

    async getMachines() {
        this.setState({ loading: true })

        try {

            let snap = await db.collection('machines').orderBy('createAt', "desc").get()

            this.setState({ machineList: snap.docs })

            let searchable = snap.docs.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })
            
            let { currentUser } = this.context

            if (currentUser.uid !== process.env.REACT_APP_ADMIN_UID) {
                searchable = searchable.filter(el => {
                    return el.isActive
                })
            }            

            this.setState({ searchList: searchable })

        } catch (error) {

            console.log(error);

        }

        this.setState({ loading: false })
    }

    componentDidMount() {
        this.getMachines()
    }

    searchMachine(e) {


        let { searchKey, machineList } = this.state

        let results = machineList.filter(machine => {
            let { name, description } = machine.data()
            return name.toLowerCase().includes(searchKey) || description.toLowerCase().includes(searchKey)
        })

        let searchable = results.map(el => {
            return {
                uid: el.id,
                ...el.data()
            }

        })


        this.setState({ searchList: searchable })
    }

    onKeyDown(e) {

        if (e.keyCode === 13) this.searchMachine()

        if (e.keyCode === 27) {
            this.setState({ searchKey: '' })

            let searchable = this.state.machineList.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })

            this.setState({ searchList: searchable })
        }

    }

    updateMachine(row) {
        this.props.history.push('/machine-update', { machine: row })
    }

    async changeStatus(row) {

        let wait = toast.warning("Please wait!")

        try {

            if (!row.isActive) {

                let snap = await db.collection('currencies').doc(row.currencyRef).get()
                let snap2 = await db.collection('categories').doc(row.categoryRef).get()

                if (!snap.exists || !snap2.exists) {
                    toast.update(wait, { autoClose: 1 })

                    toast.error('can not update status as the currency or category for the machine has been removed')

                    return
                }
            }


            await db.collection("machines").doc(row.uid).update({
                isActive: !row.isActive
            })

            await this.getMachines()

            toast.success("success");

        } catch (error) {

            console.log(error);

            toast.error("you don't have enough permission");

        }

        toast.update(wait, { autoClose: 1 })

    }

    onChange(e) {
        this.setState({ searchKey: e.target.value })
    }
    onChangeStatus(e) {

        let { value } = e.target
        this.setState({ status: value })


        let { machineList } = this.state

        let searchable = []


        if (value === 'all') {

            searchable = machineList.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })

        } else {

            let results = machineList.filter(machine => {
                let { isActive } = machine.data()
                return isActive.toString() === value
            })

            searchable = results.map(el => {
                return {
                    uid: el.id,
                    ...el.data()
                }

            })

        }

        this.setState({ searchList: searchable })

    }

    formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

    columns = [
        {
            name: 'Machine',
            selector: 'name',
            sortable: true,
            cell: row => {
                return <Link to={`..?machine=${row.uid}`}>{row.name}</Link>
            }
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
        },
        {
            name: 'Currency',
            selector: 'currencyCode',
            sortable: true,
        },
        {
            name: 'Category',
            selector: 'categoryName',
            sortable: true,
        },
        {
            name: 'Create date',
            selector: 'createAt',
            sortable: true,
            cell: row => this.formatDate(row.createAt)
        },
        {
            name: 'Image',
            cell: row => {

                return row.image ? <img style={{ cursor: 'pointer' }} onClick={() => { window.open(row.image, '_blank') }} src={row.image} width="50px" height="auto" alt="" /> : "No image"

            }
        },
        {
            name: 'Active',
            cell: row => {

                return row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />

            },
        },
        {
            name: "Action",
            cell: row => {

                let { currentUser } = this.context

                const statusBtn = currentUser !== null && currentUser.uid === process.env.REACT_APP_ADMIN_UID ? 
                    <Button size="sm" title="change status" onClick={() => { this.changeStatus(row) }} >
                        {row.isActive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}
                    </Button>
                    
                    : null

                const deleteBtn = currentUser !== null && currentUser.uid === process.env.REACT_APP_ADMIN_UID ?
                    <Button size="sm" variant="danger" title="delete category" onClick={() => { this.deleteMachine(row) }} >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    : null

                const editBtn = currentUser !== null && currentUser.uid === row.userRef ?
                    <Button size="sm" variant="success" title="edit country" onClick={() => { this.updateMachine(row) }} >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    : null

                return <ButtonGroup>
                    {statusBtn}
                    {editBtn}
                    {deleteBtn}
                </ButtonGroup>

            },
            ignoreRowClick: true,
            allowOverflow: true,
        }
    ]

    toggleFilter() {

        this.setState({ open: !this.state.open })
    }

    deleteMachine = async (row) => {

        let result = await Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Delete`,
        })

        if (result.value === true) {
            let wait = toast.warning("please wait");

            try {

                await db.collection("machines").doc(row.uid).delete()

                let pattern = /^https:\/\/firebasestorage.*\/o\/(.*)\?.*$/

                let matches = pattern.exec(decodeURI(row.image))


                if (row.image && matches.length === 2) {
                    let id = matches[1].replace(/%3A/g, ":")

                    try {
                        await storage.ref(id).delete()

                    } catch {
                        console.log('img not deleted');
                    }
                }

                await this.getMachines()

                toast.update(wait, {
                    autoClose: 1
                })

                toast.success("success");

            } catch (error) {

                console.log(error);

                toast.error("you don't have enough permission");

            }

            toast.update(wait, {
                autoClose: 1
            })

        }

    }

    onClickDownload() {

        let data = this.state.machineList.map(machine => {
            let { name,
                categoryName,
                currencyCode,
                description,
                investmentCost,
                installationCost,
                amortisationPeriod,
                residualValue,
                floorSpace,
                maintenance,
                elecUsed,
                availability,
                performance,
                quality
            } = machine.data()

            let oee = ((parseFloat(availability) / 100) * (parseFloat(performance) / 100) * (parseFloat(quality) / 100)) * 100

            oee = isNaN(oee) ? 0 : Math.round(oee)

            return {
                "id": machine.id,
                "category": categoryName,
                "machine": name,
                description,
                "currency": currencyCode,
                "investment cost": investmentCost,
                "installation cost": installationCost,
                "amortisation period (years)": amortisationPeriod,
                "residual value": residualValue,
                "floor space (m^2)": floorSpace,
                "maintenance": maintenance,
                "elec used / hour (Kwh)": elecUsed,
                "availability %": availability,
                "performance %": performance,
                "quality %": quality,
                "OEE %": oee,
                "Updated": "No"
            }
        })
        exportToExcel(data);
    }

    advanceMenu() {
        let { currentUser } = this.context

        if (currentUser !== null && currentUser.uid === process.env.REACT_APP_ADMIN_UID) {
           return (
               <ButtonGroup className="ml-1">
                   <Button variant='success' as={Link} to="/machine-import" size="sm" title="import machine">
                       < FontAwesomeIcon icon={faUpload} />
                   </Button>
                   <Button variant='success' size="sm" title="export machines" onClick={() => this.onClickDownload()}>
                       < FontAwesomeIcon icon={faDownload} />
                   </Button>
                   <Button variant='secondary' size="sm" title="upload machine images in bulk" as={Link} to="/machine-images">
                       < FontAwesomeIcon icon={faImage} />
                   </Button>
               </ButtonGroup>
           )
        }
    }

    addActiveColumn(columns) {
        let { currentUser } = this.context

        if (currentUser !== null && currentUser.uid !== process.env.REACT_APP_ADMIN_UID) {
            return columns.filter(column => column.name !== "Active" && column.name !== "Action" && column.name !== "Currency" && column.name !== "Create date")
        }

        return columns
    }

    isAdmin() {
        let { currentUser } = this.context

        return currentUser !== null && currentUser.uid === process.env.REACT_APP_ADMIN_UID
    }

    render() {

        let localColumns = this.addActiveColumn(this.columns)

        return (
            <>
                <ToastContainer />
                <Card>
                    <Card.Header>

                        <Row>
                            <Col>
                                Machine
                            </Col>
                            <Col className="text-right">
                                <Button size="sm" as={Link} to="/machine-create" title="create machines">
                                    < FontAwesomeIcon icon={faPlus} />
                                </Button>
                                {this.advanceMenu()}

                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        onChange={(e) =>this.onChange(e)}
                                        onKeyDown={(e) => this.onKeyDown(e)}
                                        placeholder="search machines"
                                        aria-label="search machines"
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKey}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="success" size="x" onClick={() => this.searchMachine()}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                {
                                    this.isAdmin() ? 

                                    <Form.Group controlId="status">
                                        <Form.Control as="select" name="currencyCode" value={this.state.status || ''} onChange={(e) => this.onChangeStatus(e)}>
                                            <option disabled={true} value={""}>--select a status--</option>
                                            <option value="all">All</option>
                                            <option value={true}>Active</option>
                                            <option value={false}>Inactive</option>
                                        </Form.Control>
                                    </Form.Group> 
                                    
                                    : null
                                }
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={12} sm="12" >
                                <div className="d-grid gap-2">
                                    <Button title="filter" onClick={() => this.toggleFilter()}>
                                        <FontAwesomeIcon icon={faFilter} />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={12} sm="12">
                                <Collapse in={this.state.open}>
                                    <Card></Card>
                                </Collapse>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <DataTable
                                    pagination={true}
                                    columns={localColumns}
                                    data={this.state.searchList}
                                    progressPending={this.state.loading}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
}
