import { toast } from "react-toastify";
import { db } from "../services/firebase";

export async function fetchStructuredActiveDocsFromCollections(collection) {   
  

    let collections = [];
    let collectionSnap = await db.collection(collection).where('isActive', '==', true).get()

    collectionSnap.docs.forEach(collect => collections.push({
        id: collect.id,
        ...collect.data()
    }))

    
     return collections
}

export async function fetchStructuredRates(rateID) {
    
    let rateSnap = await db.collection('rates').doc(rateID).get()
    let rate = rateSnap.exists ? rateSnap.data().rate : 0
    
    if(rate === 0) {
        toast.warning('exchange rate have not been added')
    }

    return rate
} 