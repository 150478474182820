import React, { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { db, storage } from '../services/firebase';
import { Row, Col, Image, Button, Badge, ProgressBar  } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faWindowClose } from '@fortawesome/free-solid-svg-icons'
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function MyDropzone() {

    let [images, setImages] = useState([]);
    let [loading, setLoading] = useState(false);

    const onDrop = useCallback(async acceptedFiles => {

        let imageFiles = []

        let wait = toast.warning('Please wait', {autoClose :false});


        for (let file of acceptedFiles) {
            let id = file.name.match(/(.*)\..*/)[1]
            let machine = await db.collection('machines').doc(id).get();
            let base64 = await getBase64(file)

            imageFiles.push({
                machineId: id,
                name: file.name,
                base64,
                file : file,
                progress: 0,
                exists: machine.exists
            });

            
        }
        

        setImages(imageFiles);

        toast.update(wait, {autoClose :1})

    }, [])



    const { getRootProps, getInputProps, isDragActive,
        isDragAccept,
        isDragReject } = useDropzone({ accept: 'image/*', onDrop })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const removeImage = (imageId) => {

        let localImages = images.filter(image => image.machineId !== imageId);

        setImages(localImages);

    }

    const uploadImage = async (image) => {

        let filename = `${new Date().toISOString()}_${Math.random()}`
        let resultUpload = await storage.ref(filename).put(image)
        return await resultUpload.ref.getDownloadURL()
    }

    const syncImages = async () => {

        setLoading(true)
        let hasInvalid = images.filter(img => img.exists === false).length > 0;
        let localImages = images.map((x) => x);

        if (hasInvalid) {
            toast.error('Some images are invalid please remove them and re-try');
            setLoading(false)
            return
        }

        if (localImages.length === 0) {
            toast.error('no images found');
            setLoading(false)
            return
        }

        let wait = toast.warning('Please wait!', {autoClose :false});

        let batch = db.batch();

        for (let image of localImages) {
            image.progress = 50
            setImages(localImages)
            let machineImage = await uploadImage(image.file)
            let machine = await db.collection('machines').doc(image.machineId).get();

            batch.update(machine.ref, {image: machineImage});
            
            image.progress = 100
            setImages(localImages)
           
        }

        await batch.commit()

        toast.update(wait, {autoClose :1})
        toast.success('Image synced successfully');
        setLoading(false)

    }

    return (
        <div className="container">
            <ToastContainer/>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>

            <Row className="mt-2">
                <Col>
                    <Button variant="success" disabled={loading} onClick={() => setImages([])}>
                        Clear all
                    </Button>
                    <Button variant="success" className="ml-1" disabled={loading} onClick={() => syncImages()}>
                        Sync
                    </Button>
                </Col>
            </Row>

            <Row className="mt-4">
                {
                    images.map(image => {

                        return (
                            <Col md={2} key={image.machineId} className="image-wrapper">
                                <div className="remove-img">
                                    <Button onClick={() => removeImage(image.machineId)} variant="danger" size="sm">
                                        < FontAwesomeIcon icon={faWindowClose} />
                                    </Button>
                                </div>
                                <Image src={image.base64} thumbnail />
                                <small>{image.name}</small>
                                <br/>
                                {image.exists ? "" : <Badge className=" bg-danger">INVALID</Badge>}
                                <ProgressBar animated now={image.progress} />
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}
