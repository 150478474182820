import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Card, Image, Row, Col } from 'react-bootstrap'

class More extends Component {

   

    render() {

        let { selectedMachine} = this.props

        let machineImage = null

        if (selectedMachine && selectedMachine.image) {
            machineImage = <Image thumbnail style={{ maxHeight: "472px" }} src={selectedMachine.image} alt="no image" />
        }

        

        return (
            <Card className="mb-5">
                <Card.Body>
                    <Row>
                        <Col className="text-center">
                            {machineImage}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }
}
const mapStateToProps = state => {

    let { calculation } = state

    return {
        selectedMachine: calculation.machine,
    }
}


export default connect(mapStateToProps)(More);