import React, { useEffect, useState } from 'react'
import { Form, InputGroup, Row, Col, Button } from 'react-bootstrap';
import { db } from '../services/firebase';
import { ToastContainer, toast } from 'react-toastify';
import { addCountry, updateCountry } from '../validation/country';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useHistory } from 'react-router-dom';

export default function CountryForm(props) {

    const [currencies, setCurrencies] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        labourRate: '',
        indRatio: '',
        elec: '',
        factoryCost: '',
        interestRates: "",
        insurance: "",
        shift1Premium: "",
        shift2Premium: "",
        shift3Premium: "",
        uid: "",
        currencyCode: ""
    })
   
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        getCurrencies()

        document.addEventListener("wheel", function (event) {
            if (document.activeElement.type === "number") {
                document.activeElement.blur();
            }
        });

    }, [])


    useEffect(() => {

        if (props.update) {

            

            if (props.updateData.currencyCode) {

                let selectedCur = currencies.filter(cr => {
                    return cr.id === props.updateData.currencyRef
                })


                if (selectedCur.length === 0) {

                    let localFormData = JSON.stringify(props.updateData)

                    localFormData = JSON.parse(localFormData)

                    localFormData.currencyCode = ""
                    localFormData.currencyRef = ""

                    setFormData(localFormData)

                }else{
                    setFormData(props.updateData)
                }
            }


        }

    }, [currencies, props.update, props.updateData])


    async function getCurrencies() {

        let waiting = toast.warning("Please wait! data is loading")

        let snap = await db.collection("currencies").where('isActive', '==', true).get()
        let localCurrencies = []

        snap.docs.forEach(currency => {
            let {
                name,
                code
            } = currency.data()

            localCurrencies.push({
                name,
                code,
                id: currency.id
            })
        })

        setCurrencies(localCurrencies)

        toast.update(waiting, {
            autoClose: 1
        })
    }

    async function onSubmit(e) {
        e.preventDefault()
        setLoading(true)

        try {
            if (!props.update) {
                await createCountry()
            }

            if (props.update) {
                await updateExistingCountry()
            }

        } catch (error) {

            console.log(error);


            let errorMsg = "you don't have enough permission"

            if (error.details) {
                setErrors(error.details)
                errorMsg = 'Please check you details'
            }


            toast.error(errorMsg);

        }


        setLoading(false)
    }

   

    async function createCountry() {


        await addCountry.validateAsync({ ...formData }, {
            abortEarly: false
        });

        await db.collection('countries').add({
            ...formData,
            isActive: true, createAt: new Date().toISOString()
        })

        redirect()

    }

    async function updateExistingCountry() {


        await updateCountry.validateAsync({
            ...formData
        }, {
            abortEarly: false
        });

        await db.collection('countries').doc(formData.uid).update({
            ...formData,
            updateAt: new Date().toISOString()
        })

        redirect()
    }

    function isError(filed) {

        for (let error of errors) {
            if (error.context.key === filed) return {
                status: true,
                message: error.message
            }
        }

        return {
            status: false,
            message: ''
        }
    }

    function redirect() {

        let msg = `country ${props.update ? 'updated' : 'added'} successfully`

        Swal.fire({
            title: "Success",
            text: msg,
            type: 'success'
        }).then(result => {

            if (result.value) history.push('/country')

        })
    }

    function onChange(e) {
        let { name, value } = e.target

        let localFormData = JSON.stringify(formData)

        localFormData = JSON.parse(localFormData)

        localFormData[name] = value

        setFormData(localFormData)
    }

    function currencyOnChange (e) {
        let {value} = e.target

        let localFormData = JSON.stringify(formData)

        localFormData = JSON.parse(localFormData)

        let selectedCurrency = currencies.filter(cr => {
            return cr.code === value
        })

        
        localFormData.currencyCode = (selectedCurrency[0]).code
        localFormData.currencyRef = (selectedCurrency[0]).id

        setFormData(localFormData)
    }


    return (
        <div>
            <ToastContainer />

            <Form onSubmit={onSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control isInvalid={isError('name').status} type="text" value={formData.name || ''} name="name" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('name').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="currency">
                            <Form.Label>Currency</Form.Label>
                            <Form.Control isInvalid={isError('currencyCode').status} as="select" value={formData.currencyCode || ''} onChange={currencyOnChange}>
                                <option disabled={true} value={""} >--select currency--</option>
                                {
                                    currencies.map(currency => {
                                        return <option key={currency.id} value={currency.code}>{currency.code}</option>
                                    })
                                }
                            </Form.Control>
                            
                            <Form.Control.Feedback type="invalid">
                                {isError('currencyCode').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId="labourRate">
                            <Form.Label>Labor Rate</Form.Label>
                            <Form.Control isInvalid={isError('labourRate').status} type="number" value={formData.labourRate || ''} name="labourRate" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('labourRate').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="indRatio">
                            <Form.Label>Ind ratio</Form.Label>
                            <Form.Control isInvalid={isError('indRatio').status} type="number" value={formData.indRatio || ''} name="indRatio" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('indRatio').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId="elec">
                            <Form.Label>Elec (Kwh)</Form.Label>

                            <InputGroup>
                                <Form.Control isInvalid={isError('elec').status} type="number" value={formData.elec || ''} name="elec" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">Kwh</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('elec').message}
                                </Form.Control.Feedback>
                            </InputGroup>


                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="factoryCost">
                            <Form.Label>Factory cost per m^2 / year</Form.Label>
                            <Form.Control isInvalid={isError('factoryCost').status} type="number" value={formData.factoryCost || ''} name="factoryCost" onChange={onChange} />
                            <Form.Control.Feedback type="invalid">
                                {isError('factoryCost').message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>


                <Row>
                    <Col md={6}>
                        <Form.Group controlId="interestRates">
                            <Form.Label>Interest Rates</Form.Label>

                            <InputGroup>
                                <Form.Control isInvalid={isError('interestRates').status} type="number" value={formData.interestRates || ''} name="interestRates" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('interestRates').message}
                                </Form.Control.Feedback>
                            </InputGroup>


                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="insurance">
                            <Form.Label>Machine insurance</Form.Label>

                            <InputGroup>
                                <Form.Control isInvalid={isError('insurance').status} type="number" value={formData.insurance || ''} name="insurance" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>

                                <Form.Control.Feedback type="invalid">
                                    {isError('insurance').message}
                                </Form.Control.Feedback>
                            </InputGroup>


                        </Form.Group>
                    </Col>
                </Row>



                <Row>
                    <Col xs={6} md={4}>
                        <Form.Group controlId="shift1Premium">
                            <Form.Label>Shift premium # 1 shift</Form.Label>

                            <InputGroup>
                                <Form.Control isInvalid={isError('shift1Premium').status} type="number" value={formData.shift1Premium || ''} name="shift1Premium" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('shift1Premium').message}
                                </Form.Control.Feedback>
                            </InputGroup>


                        </Form.Group>
                    </Col>
                    <Col xs={6} md={4}>
                        <Form.Group controlId="shift2Premium">
                            <Form.Label>Shift premium # 2 shift</Form.Label>

                            <InputGroup>
                                <Form.Control isInvalid={isError('shift2Premium').status} type="number" value={formData.shift2Premium || ''} name="shift2Premium" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('shift2Premium').message}
                                </Form.Control.Feedback>
                            </InputGroup>


                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="shift3Premium">
                            <Form.Label>Shift premium # 3 shift</Form.Label>

                            <InputGroup>
                                <Form.Control isInvalid={isError('shift3Premium').status} type="number" value={formData.shift3Premium || ''} name="shift3Premium" onChange={onChange} />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {isError('shift3Premium').message}
                                </Form.Control.Feedback>
                            </InputGroup>


                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit" disabled={loading}>
                    Submit
                </Button>
            </Form>
        </div>
    )
}
