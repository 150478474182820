import {
    toast
} from 'react-toastify'
import {
    SET_MACHINE,
    SET_CATEGORY,
    SET_EXCHANGE,
    SET_COUNTRY,
    SET_SHIFT,
    SET_HOURS_PER_SHIFT,
    SET_DAYS_PER_YEAR,
    SET_MARGIN,
    SET_SGA,
    SET_CURRENCY,
    SET_DEPRECATION,
    SET_INTEREST,
    SET_MAINTENANCE,
    SET_FLOOR_SPACE,
    SET_INSURANCE,
    SET_UTILITIES,
    SET_HOURS_PER_YEAR,
    SET_OPERATOR,
    SET_SUPPORT,
    SET_SELLING_PRICE_PER_HOUR,
    REST_CALCULATOR,
    SET_COUNTRY_EXCHANGE_RATE
} from '../actionType'
import {
    fetchStructuredRates
} from './helpers'
import formulajs from '@formulajs/formulajs'

export function setMachine(data) {

    let machine = {
        name: ''
    }

    if (data !== undefined) machine = data

    return {
        type: SET_MACHINE,
        payload: machine
    }
}
export function setCategory(data) {
    return {
        type: SET_CATEGORY,
        payload: data
    }
}
export async function setExchangeRate(machineCurrency, selectedCurrency) {

    let rate = 1;

    if (machineCurrency && selectedCurrency && (machineCurrency !== selectedCurrency)) {
        let wait = toast.warning('Please wait!')

        rate = await fetchStructuredRates(`${machineCurrency}_${selectedCurrency}`)

        toast.update(wait, {
            autoClose: 1
        })
    }


    return {
        type: SET_EXCHANGE,
        payload: rate

    }
}
export async function setCountryExchangeRate(selectedCountryCurrencyCode, selectedCurrency) {

    let rate = 1;

    if (selectedCountryCurrencyCode && selectedCurrency && (selectedCountryCurrencyCode !== selectedCurrency)) {
        let wait = toast.warning('Please wait!')

        rate = await fetchStructuredRates(`${selectedCountryCurrencyCode}_${selectedCurrency}`)

        toast.update(wait, {
            autoClose: 1
        })
    }


    return {
        type: SET_COUNTRY_EXCHANGE_RATE,
        payload: rate

    }
}
export function setCountry(data) {
    return {
        type: SET_COUNTRY,
        payload: data
    }
}

export function setCurrency(data) {
    return {
        type: SET_CURRENCY,
        payload: data
    }
}
export function setShift(data) {
    return {
        type: SET_SHIFT,
        payload: data
    }
}
export function setHourPerShift(data) {
    return {
        type: SET_HOURS_PER_SHIFT,
        payload: data
    }
}
export function setDaysPerYear(data) {
    return {
        type: SET_DAYS_PER_YEAR,
        payload: data
    }
}
export function setMargin(data) {
    return {
        type: SET_MARGIN,
        payload: data
    }
}
export function setSGA(data) {
    return {
        type: SET_SGA,
        payload: data
    }
}

export function setDepreciation(selectedMachine, exchangeRate) {

    let deprecation = 0


    if (selectedMachine && exchangeRate) {

        let {
            amortisationPeriod,
            investmentCost,
            installationCost,
            residualValue
        } = selectedMachine

        investmentCost = parseFloat(investmentCost)
        installationCost = parseFloat(installationCost)
        residualValue = parseFloat(residualValue)
        amortisationPeriod = parseFloat(amortisationPeriod)

        deprecation = ((investmentCost + installationCost - (residualValue * investmentCost / 100)) / amortisationPeriod) * exchangeRate

        deprecation = Math.round(deprecation)
    }

    return {
        type: SET_DEPRECATION,
        payload: deprecation
    }
}

export function setInterest(machine, country, exchangeRate) {

    let interest = 0

    if (machine && country && exchangeRate) {

        let {
            investmentCost,
            amortisationPeriod,
            installationCost
        } = machine

        let {
            interestRates
        } = country

        investmentCost = parseFloat(investmentCost)
        installationCost = parseFloat(installationCost)
        interestRates = parseFloat(interestRates)
        amortisationPeriod = parseFloat(amortisationPeriod)


        interest = (-(formulajs.CUMIPMT(interestRates / 100 / 12, amortisationPeriod * 12, investmentCost + installationCost, 1, amortisationPeriod * 12, 0)) / amortisationPeriod) * exchangeRate


        interest = Math.round(interest)

        interest = isNaN(interest) ? 0 : interest

    }

    return {
        type: SET_INTEREST,
        payload: interest
    }

}

export function setMaintenance(machine, exchangeRate) {

    let value = 0

    if (machine && exchangeRate) {

        let {
            investmentCost,
            maintenance
        } = machine

        investmentCost = parseFloat(investmentCost)
        maintenance = parseFloat(maintenance)


        value = investmentCost * (maintenance / 100) * exchangeRate


        value = Math.round(value)

    }

    return {
        type: SET_MAINTENANCE,
        payload: value
    }
}

export function setFloorSpace(machine, country, exchangeRate) {
    let value = 0

    if (machine && country && exchangeRate) {
        let {
            floorSpace
        } = machine

        let {
            factoryCost
        } = country

        factoryCost = parseFloat(factoryCost)
        floorSpace = parseFloat(floorSpace)

        value = factoryCost * floorSpace * exchangeRate

        value = Math.round(value)


        value = isNaN(value) ? 0 : value
    }

    return {
        type: SET_FLOOR_SPACE,
        payload: value
    }
}
export function setInsurance(machine, country, exchangeRate) {

    let value = 0

    if (machine && country && exchangeRate) {
        let {
            investmentCost
        } = machine

        let {
            insurance
        } = country

        investmentCost = parseFloat(investmentCost)
        insurance = parseFloat(insurance)

        value = ((insurance / 100) * investmentCost) * exchangeRate

        value = Math.round(value)

        value = isNaN(value) ? 0 : value

    }

    return {
        type: SET_INSURANCE,
        payload: value
    }


}
export function setUtilities(machine, country, hoursPerYear, countryExchangeRate) {


    let value = 0

    if (machine && country && hoursPerYear) {

        let {
            elecUsed
        } = machine

        let {
            elec
        } = country


        elecUsed = parseFloat(elecUsed)
        elec = parseFloat(elec)

        value = ((elecUsed * elec) * hoursPerYear) * countryExchangeRate

        value = Math.round(value)

        value = isNaN(value) ? 0 : value
    }

    return {
        type: SET_UTILITIES,
        payload: value
    }
}

export function setHourPerYear(shift, hoursPerShift, daysPerYear) {

    let value = 0
    
    if (!isNaN(parseInt(shift)) && !isNaN(parseFloat(hoursPerShift)) && !isNaN(parseFloat(daysPerYear))) {
        value = parseInt(shift) * parseFloat(hoursPerShift) * parseFloat(daysPerYear)        
    }

    return {
        type: SET_HOURS_PER_YEAR,
        payload: value
    }
}

export function setOperator(country, countryExchangeRate, shift) {

    let value = 0

    let {
        labourRate
    } = country
    let countryShift = `shift${shift}Premium`
    let selectedCountryShift = 0

    if (country && shift && country[countryShift]) selectedCountryShift = country[countryShift]

    value = labourRate * countryExchangeRate * (1 + (selectedCountryShift / 100))

    value = isNaN(value) ? 0 : value

     return {
         type: SET_OPERATOR,
         payload: value
     }
}

export function setSupport(country, operator) {
    let value = 0

    let {
        indRatio
    } = country

    value = indRatio * operator

    value = isNaN(value) ? 0 : value

    return {
        type: SET_SUPPORT,
        payload: value
    }
}

export function setSellingPricePerHour(price) {
    return {
        type: SET_SELLING_PRICE_PER_HOUR,
        payload: price
    }
}

export function resetCalculator() {
    return {
        type: REST_CALCULATOR
    }
}