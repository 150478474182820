export const FETCH_ALL = 'FETCH_ALL'
export const FETCH_MACHINES = 'FETCH_MACHINES'
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES'
export const FETCH_EXCHANGE = 'FETCH_EXCHANGE'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const FETCH_META_DATA = 'FETCH_META_DATA'

//calculations
export const REST_CALCULATOR = 'REST_CALCULATOR'
export const SET_HOURS_PER_YEAR = 'SET_HOURS_PER_YEAR'
export const SET_MACHINE = 'SET_MACHINE'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_EXCHANGE = 'SET_EXCHANGE'
export const SET_COUNTRY_EXCHANGE_RATE = 'SET_COUNTRY_EXCHANGE_RATE'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_SHIFT = 'SET_SHIFT'
export const SET_HOURS_PER_SHIFT = 'SET_HOURS_PER_SHIFT'
export const SET_DAYS_PER_YEAR = 'SET_DAYS_PER_YEAR'
export const SET_MARGIN = 'SET_MARGIN'
export const SET_SGA = 'SET_SGA'
export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_DEPRECATION = 'SET_DEPRECATION'
export const SET_INTEREST = 'SET_INTEREST'
export const SET_MAINTENANCE = 'SET_MAINTENANCE'
export const SET_FLOOR_SPACE = 'SET_FLOOR_SPACE'
export const SET_INSURANCE = 'SET_INSURANCE'
export const SET_UTILITIES = 'SET_UTILITIES'
export const SET_OPERATOR = 'SET_OPERATOR'
export const SET_SUPPORT = 'SET_SUPPORT'
export const SET_SELLING_PRICE_PER_HOUR = 'SET_SELLING_PRICE_PER_HOUR'