import React, { Component } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import RegisterForm from '../components/RegisterForm'
import { Link } from 'react-router-dom'

export default class Register extends Component {
    render() {
        return (
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center">Register</h2>
                            <RegisterForm redirect='login' />
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        Already have an account? <Link to="/login">Log In</Link>
                    </div>
                </Col>
            </Row>


        )
    }
}
