const Joi = require('joi')


const addCountry = Joi.object({
       name : Joi.string().required(),
       labourRate: Joi.number().required().label("labour rate"),
       indRatio: Joi.number().required().label("ind ratio"),
       elec: Joi.number().required().label("elec"),
       factoryCost: Joi.number().required().label("factory cost"),
       interestRates: Joi.number().required().label("interest rates"),
       insurance: Joi.number().required().label("machine insurance"),
       shift1Premium: Joi.number().required().label("Shift premium # 1 shift"),
       shift2Premium: Joi.number().required().label("Shift premium # 2 shift"),
       shift3Premium: Joi.number().required().label("Shift premium # 3 shift"),
       currencyCode: Joi.string().required().label("currency"),
}).options({
    allowUnknown: true
})

const updateCountry = Joi.object({
       name : Joi.string().required(),
       labourRate: Joi.number().required().label("labour rate"),
       indRatio: Joi.number().required().label("ind ratio"),
       elec: Joi.number().required().label("elec"),
       factoryCost: Joi.number().required().label("factory cost"),
       interestRates: Joi.number().required().label("interest rates"),
       insurance: Joi.number().required().label("machine insurance"),
       shift1Premium: Joi.number().required().label("Shift premium # 1 shift"),
       shift2Premium: Joi.number().required().label("Shift premium # 2 shift"),
       shift3Premium: Joi.number().required().label("Shift premium # 3 shift"),
       currencyCode: Joi.string().required().label("currency"),
}).options({allowUnknown : true})

export {
    addCountry,
    updateCountry
}