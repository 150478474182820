import React, { Component } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap';
import MachineForm from '../../components/MachineForm';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
export default class Update extends Component {

    constructor () {
        super()

        this.state = {
            data : {
                uid : null
            }
        }
    }

     componentDidMount() {
         let data = this.props.location.state.machine;

         if (!data) {

             Swal.fire({
                 title: "Fail",
                 text: "machine not found",
                 type: 'error'
             })

             this.props.history.push('/machine')
         }

         this.setState({
             data
         })

     }

    render() {
        return (
            <div>
               <Card>
                    <Card.Header>
                        <Row>
                    <Col xs={4} md={4}>
                        <Button size="sm" variant="warning" as={Link} to="/machine" title="back">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                    <Col className="align-items-center">
                        Update Machine
                    </Col>
                </Row>

                    </Card.Header>
                <Card.Body>
                    <MachineForm updateData={this.state.data} update={true} history={this.props.history} /> 
                </Card.Body>
               </Card>
            </div>
        )
    }
}
